import {
  Component,
  OnInit,
  inject,
  effect,
  HostListener,
  DestroyRef,
} from "@angular/core";
import { CustomersService } from "src/app/customers/customers.service";
import { DataService } from "src/app/data.service";
import {
  ColumnState,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from "ag-grid-community";
import * as _latinize from "latinize";
import { MatDialog } from "@angular/material/dialog";
const latinize = _latinize;
import { db } from "src/app/db.service";
import { Observable } from "rxjs";
import { slideAnimation } from "src/app/shared/fade.animation";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-customers-full",
  templateUrl: "./customers-full.component.html",
  styleUrls: ["./customers-full.component.css"],
  animations: [slideAnimation],
})
export class CustomersFullComponent implements OnInit {
  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.showFilterOptions = false;
    }
  }

  _dataService = inject(DataService);
  destroyRef = inject(DestroyRef);
  _customersService = inject(CustomersService);

  dataLoading: boolean = false;
  navButtons = [
    {
      name: "Yeni Tərəf-Müqabil",
      icon: "add",
      function: () => this._customersService.customerDialog(),
    },
  ];
  showFilterOptions: boolean = false;
  selectedCustomerGroups = this._formBuilder.group({});

  customerGroups =
    this._dataService.getLS(`${this._dataService.dbTable}_customerFilters`) ??
    this._dataService
      .customerGroups()
      .map((customerGrp) => ({ ...customerGrp, filter: false }));
  customerGroupCategoryIds = [];

  columnDefs = [
    {
      headerName: "id",
      field: "customerId",
      hide: true,
    },
    {
      headerName: "Tərəf-Müqabil",
      field: "customerName",
      sortable: true,
      flex: 3,
      minWidth: 100,
      filterValueGetter: (params) =>
        this._dataService.latinizeText(params, "customerName"),
    },
    // {
    //   headerName: "Tipi",
    //   field: "customerType",
    //   sortable: true,
    //   flex: 2,
    //   minWidth: 150,
    //   filterValueGetter: (params) =>
    //     this._dataService.latinizeText(params, "customerType"),
    // },
    {
      headerName: "Əvvələ Debit",
      field: "balanceBefore",
      sortable: true,
      flex: 2,
      minWidth: 150,
      cellRenderer: (params) => {
        return params.value >= 0 ? params.value : null;
      },
    },
    {
      headerName: "Əvvələ Kredit",
      field: "balanceBefore",
      sortable: true,
      flex: 2,
      minWidth: 150,
      cellRenderer: (params) => {
        return params.value < 0 ? -params.value : null;
      },
    },
    {
      headerName: "Gedən",
      field: "incomeTotal",
      sortable: true,
      flex: 2,
      minWidth: 120,
      cellRenderer: (params) => {
        return params.data.sellsTotal + params.data.outgoingTotal;
      },
    },
    {
      headerName: "Gələn",
      field: "stocksTotal",
      sortable: true,
      flex: 2,
      minWidth: 150,
      cellRenderer: (params) => {
        return params.data.stocksTotal + params.data.incomeTotal;
      },
    },
    {
      headerName: "Fərq",
      field: "balance",
      sortable: true,
      flex: 2,
      minWidth: 120,
      cellClass: (params) => {
        return params.value < 0 ? "minusValueRedColor" : "";
      },
    },
    {
      headerName: "Yekun Debit",
      field: "finalBalance",
      colId: "finalDebit",
      sortable: true,
      flex: 2,
      minWidth: 150,
      cellRenderer: (params) => {
        return params.value >= 0 ? params.value : null;
      },
      hide: chipSelected.startsWith("Kreditorlar") ? true : false,
    },
    {
      headerName: "Yekun Kredit",
      field: "finalBalance",
      sortable: true,
      colId: "finalCredit",
      flex: 2,
      minWidth: 150,
      cellRenderer: (params) => {
        return params.value < 0 ? -params.value : null;
      },
      hide: chipSelected.startsWith("Debitorlar") ? true : false,
    },
  ];
  protected gridApi!: GridApi;
  agGridData$: Observable<any[]>;

  constructor(public dialog: MatDialog, private _formBuilder: FormBuilder) {
    effect(async () => {
      if (this._dataService.customers() || this._dataService.dbChanged()) {
        this.agGridData$ = await this._customersService.getCustomersBalance();
      }
    });
  }

  async ngOnInit(): Promise<void> {
    // this._customersService.chipSelected.next("");
    this.agGridData$ = await this._customersService.getCustomersBalance();
    filterTable = false;
  }

  async getStocks() {
    const result = await db.stock.toArray();
    return result;
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.setGridOption("includeHiddenColumnsInQuickFilter", true);
    var defaultSortModel: ColumnState[] = [
      { colId: "customerName", sort: "asc", sortIndex: 0 },
    ];
    params.api.applyColumnState({ state: defaultSortModel });
    this.filterChanged();
  }

  filterDate(event: any) {
    if (event.value) {
      this._customersService.getCustomersBalance();
    }
  }

  // FILTER TABLE
  applyFilter(val) {
    this.gridApi.setGridOption("quickFilterText", val);
    this._customersService.sumDebit = 0;
    this._customersService.sumKredit = 0;
    this.gridApi.forEachNodeAfterFilterAndSort((node) => {
      this._customersService.sumDebit +=
        node.data.finalBalance >= 0 ? node.data.finalBalance : 0;
      this._customersService.sumKredit +=
        node.data.finalBalance < 0 ? node.data.finalBalance : 0;
    });
  }

  isExternalFilterPresent(): boolean {
    return filterTable !== false;
  }

  // FIXME eger debitor secsem, daha sonra filter edende, kreditorlarin toplami 0 gorunur
  doesExternalFilterPass(node: IRowNode<any>): boolean {
    let result = true;

    if (chipSelected.startsWith("Debitorlar")) {
      result = node.data.finalBalance > 0 ? true : false;
    }
    if (chipSelected.startsWith("Kreditorlar")) {
      result = node.data.finalBalance < 0 ? true : false;
    }
    if (customerGroupCategoryIds.length > 0) {
      result =
        customerGroupCategoryIds.includes(node.data.customerGroupId) && result
          ? true
          : false;
    }
    return result;
  }

  setChipSelected(val) {
    chipSelected = val;
    filterTable =
      customerGroupCategoryIds.length > 0 ? true : chipSelected ? true : false;
    this.gridApi.applyColumnState({ state: [], defaultState: { sort: null } });
    var defaultSortModel: ColumnState[] = [
      { colId: "customerName", sort: "asc", sortIndex: 0 },
      { colId: "finalDebit", hide: false },
      { colId: "finalCredit", hide: false },
    ];
    if (val == "Debitorlar") {
      defaultSortModel = [
        { colId: "finalCredit", hide: true },
        { colId: "finalDebit", hide: false, sort: "desc", sortIndex: 0 },
      ];
    }
    if (val == "Kreditorlar") {
      defaultSortModel = [
        { colId: "finalDebit", hide: true },
        { colId: "finalCredit", hide: false, sort: "asc", sortIndex: 0 },
      ];
    }
    this.gridApi.applyColumnState({ state: defaultSortModel });
    this.gridApi.onFilterChanged();
  }

  filterChanged() {
    this._dataService.setLS(
      `${this._dataService.dbTable}_customerFilters`,
      this.customerGroups
    );
    customerGroupCategoryIds = this.customerGroups
      .filter((customerGrp) => customerGrp.filter)
      .map((customerGrp) => customerGrp.id);
    this.customerGroupCategoryIds = this.customerGroups
      .filter((customerGrp) => customerGrp.filter)
      .map((customerGrp) => customerGrp.id);
    filterTable =
      customerGroupCategoryIds.length > 0 ? true : chipSelected ? true : false;
    this.gridApi.onFilterChanged();

    // CALCULATE SUMS
    this._customersService.sumDebit = 0;
    this._customersService.sumKredit = 0;
    this.gridApi.forEachNodeAfterFilterAndSort((node) => {
      this._customersService.sumDebit +=
        node.data.finalBalance >= 0 ? node.data.finalBalance : 0;
      this._customersService.sumKredit +=
        node.data.finalBalance < 0 ? node.data.finalBalance : 0;
    });
  }

  clearFilterSelections() {
    this.customerGroups.forEach((b) => (b.filter = false));
    filterTable = false;
    this.filterChanged();
  }
}
var filterTable = false;
var chipSelected = "Tərəf-Müqabillər";
var customerGroupCategoryIds = [];
