import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewChild,
  signal,
  inject,
  effect,
  HostListener,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AddStockDialogComponent } from "./stock-dialog/stock-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { DataService } from "../data.service";
import "lodash";
declare var _: any;
import * as _latinize from "latinize";
const latinize = _latinize;
import * as _moment from "moment";
const moment = _moment;

import { ConfirmationDialogComponent } from "../shared/confirmation-dialog/confirmation-dialog.component";
import { AuthService } from "../auth.service";
import { db } from "src/app/db.service";
import { liveQuery } from "dexie";
import {
  from,
  Observable,
  map,
  of,
  Subject,
  switchMap,
  defer,
  merge,
  tap,
  takeUntil,
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
} from "rxjs";
import { ToastrService } from "ngx-toastr";
import { NewMaterialDialogComponent } from "../dialogs/material-dialog/material-dialog.component";
import { ColumnState, GridApi, GridReadyEvent } from "ag-grid-community";
import { slideAnimation, toggleWrap } from "../shared/fade.animation";
import { PusherService } from "../pusher.service";
import { StockTransferComponent } from "../dialogs/stock-transfer/stock-transfer.component";
import { StockService } from "./stock.service";
import { SyncService } from "../sync.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-stock",
  templateUrl: "./stock.component.html",
  styleUrls: ["./stock.component.css"],
  animations: [slideAnimation, toggleWrap],
})
export class StockComponent implements OnInit, OnDestroy {
  _stockService = inject(StockService);
  _syncService = inject(SyncService);
  router = inject(Router);
  @ViewChild("navbar") navbar: ElementRef;
  @ViewChild("card") card: ElementRef;
  @ViewChild("cardsContainer") cardsContainer: ElementRef;
  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this._dataService.showContextMenu = false;
    }
  }
  groupSumData = [];
  materials = localStorage.hasOwnProperty("materials")
    ? JSON.parse(localStorage.getItem("materials"))
    : [];
  request: any = null;
  showSummary: boolean = localStorage.hasOwnProperty(
    this._dataService.dbTable + "showStockSummary"
  )
    ? this._dataService.getLS("showStockSummary")
    : true;
  showStockInPrice: boolean = true;

  isWrapped: boolean = false;

  // NEW
  @ViewChild("searchInput") searchInput: ElementRef;
  dataLoading = signal(true);
  selectedStockGroupId = this._dataService.getLS("selectedStockGroupId")
    ? signal(this._dataService.getLS("selectedStockGroupId"))
    : signal(this._dataService.stockGroups().find((s) => s.type == 1)?.id || 1);
  protected _selectedStockGroupId$ = new BehaviorSubject(
    this.selectedStockGroupId()
  );

  dateNow = moment(Date.now()).format("YYYY-MM-DD");
  totalIn = this._dataService.getLS("stockTotalIn") || 0;
  totalOut = this._dataService.getLS("stockTotalOut") || 0;
  totalRemaining = this._dataService.getLS("stockTotalRemaining") || 0;

  performance: number = 0;

  protected gridApi!: GridApi;

  dbUpdated: boolean = false;
  _dbUpdating$ = new BehaviorSubject(true);

  navButtons = [
    {
      name: "Anbar Girişi",
      icon: "add",
      function: () => this.openAddStockDialog(null, "Stock Entry"),
      canAccess: this._authService.loggedInUserValue.role == 1 ? true : false,
    },
    {
      name: "Anbar Çıxış",
      icon: "remove",
      function: () => this.openAddStockDialog(null, "Stock Out"),
      canAccess: this._authService.loggedInUserValue.role == 1 ? true : false,
    },
    {
      name: "Köçürmə",
      icon: "move_down",
      function: () => this.openStockTransferDialog(),
      canAccess:
        this._authService.loggedInUserValue.role == 1 ||
        this._authService.loggedInUserValue.role == 2
          ? true
          : false,
    },
  ];

  navExtraButtons = [
    {
      name: "Anbar Qalığı",
      icon: "inventory",
      function: () => this.router.navigate(["./Materials"]),
      canAccess: this._authService.loggedInUserValue.role == 1 ? true : false,
    },
  ];

  ngHeader = {
    items:
      this._authService.loggedInUserValue.role == 2
        ? this._dataService.stockGroups().filter((st) => st.type == 2)
        : this._dataService.stockGroups(),
    selectedItem: this.selectedStockGroupId(),
  };

  columnDefs = [
    {
      headerName: "id",
      field: "tId",
      hide: true,
    },
    {
      headerName: "",
      field: "tType",
      maxWidth: 50,
      cellRenderer: (params) => {
        switch (params.value) {
          case "Stock Entry":
            return '<span class="material-symbols-outlined">place_item</span>';
          case "Stock Out":
            return '<span class="material-symbols-outlined">move_item</span>';
          case "Stock Transfer":
            return '<span class="material-symbols-outlined">component_exchange</span>';
          default:
            "Anbar";
        }
      },
      cellClass: (params) => {
        switch (params.value) {
          case "Stock Entry":
            return "entryType stockEntry";
          case "Stock Out":
            return "entryType stockOut";
          case "Stock Transfer":
            return "entryType stockTransfer";
          default:
            return "entryType";
        }
      },
    },
    {
      headerName: "Material",
      field: "materialName",
      flex: 3,
      minWidth: 100,
      filterValueGetter: (params) =>
        this._dataService.latinizeText(params, "materialName"),
      cellClass: () => "justify-content-start",
      cellRenderer: (params) => {
        return params.data.refersTo
          ? `${params.value}<span>R</span>`
          : params.value;
      },
    },
    {
      headerName: "Miqdar",
      field: "quantity",
      sortable: true,
      flex: 2,
      minWidth: 120,
      cellClass: (params) => {
        return params.value < 0 ? "minusValueRedColor" : "";
      },
      cellRenderer: (params) =>
        params.value
          ? params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          : "*",
      cellStyle: { "word-spacing": "-2px !important", "white-space": "nowrap" },
    },
    {
      headerName: "Qiymət",
      field: "price",
      sortable: true,
      flex: 2,
      minWidth: 120,
      cellRenderer: (params) =>
        params.value
          ? params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          : "*",
      cellStyle: { "word-spacing": "-2px !important", "white-space": "nowrap" },
    },
    {
      headerName: "Toplam",
      field: "sum",
      sortable: true,
      flex: 2,
      minWidth: 120,
      cellClass: (params) => {
        return params.value < 0 ? "minusValueRedColor" : "";
      },
      cellRenderer: (params) =>
        params.value
          ? this._dataService
              .formatNumber(params.value)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          : "*",
      cellStyle: { "word-spacing": "-2px !important", "white-space": "nowrap" },
    },
    {
      headerName: "Tərəf Müqabil",
      field: "customerName",
      sortable: true,
      flex: 2,
      minWidth: 150,
      filterValueGetter: (params) =>
        this._dataService.latinizeText(params, "customerName"),
    },
    {
      headerName: "Tarix",
      field: "date",
      flex: 2,
      minWidth: 120,
      sortable: true,
      comparator: (dateA, dateB, nodeA, nodeB, isInverted) => {
        if (dateA === dateB) {
          // If dates are equal, sort by id (descending)
          const idA = nodeA.data.id;
          const idB = nodeB.data.id;
          return idA > idB ? -1 : idA < idB ? 1 : 0;
        } else {
          // Sort by date (descending)
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        }
      },
      cellRenderer: (params) => {
        return params.value.split("-").reverse().join("-");
      },
    },
    {
      headerName: "Məlumat",
      field: "details",
      flex: 2,
      minWidth: 100,
    },
    {
      headerName: "",
      field: "delete",
      flex: 1,
      maxWidth: 50,
      cellRenderer: () => {
        return `<span class='d-flex align-items-center h-100'><i class='material-icons'>more_horiz</i></span>`;
      },
      onCellClicked: (params) => {
        this.contextMenu(params);
      },
    },
  ];

  agGridData$: Observable<any[]>;
  componentDestroyed$: Subject<boolean> = new Subject();

  contextMenuData = { x: 0, y: 0, data: null };
  hoverData;

  stockGraphsUnfiltered: {}[] = this._dataService.getLS("stocksSumData") || [];
  stockGraphs: {}[] = this._dataService.getLS("stocksSumData") || [];
  showTotal: boolean = this._dataService.getLS("showStockTotals")
    ? this._dataService.getLS("showStockTotals")
    : false;
  summariesInKg: boolean = this._dataService.getLS("showStockSummariesInKG")
    ? this._dataService.getLS("showStockSummariesInKG")
    : false;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    public _dataService: DataService,
    public _authService: AuthService,
    private toastr: ToastrService,
    private _pusher: PusherService
  ) {
    effect(async () => {
      this._dataService.materials();
      this._dataService.customers();
      this.agGridData$ = this.getStockTableData();
      this._dataService.dbChanged();
    });
  }

  async ngOnInit() {
    this.navButtons = this.navButtons.filter((button) => button.canAccess);
    this.navExtraButtons = this.navExtraButtons.filter(
      (button) => button.canAccess
    );

    this.agGridData$ = this.getStockTableData();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getStockTableData(): Observable<any[]> {
    const localData = this._dataService.getLS("stockTableData") || [];
    const localObservable = defer(() => {
      return of(localData);
    });

    let start;

    const liveQueryObservable = combineLatest([
      this._dataService.dateChanged,
      this._selectedStockGroupId$.pipe(distinctUntilChanged()),
      this._dbUpdating$.pipe(distinctUntilChanged()),
    ]).pipe(
      tap(() => {
        this.dataLoading.set(true);
      }),
      switchMap(([dateChanged, stockGroupId, dbUpdating]) =>
        from(
          liveQuery(() =>
            this.getDxTransactions(
              this._dataService.startDate.value.format("YYYY-MM-DD"),
              this._dataService.endDate.value.format("YYYY-MM-DD"),
              stockGroupId
            )
          )
        ).pipe(
          map((things) => {
            const groupedThings = things.reduce((groups, thing) => {
              const key = thing.transactionId.id;
              const customer = this._dataService
                .customers()
                .find((c) => c.id === thing.customerId);
              const material = this._dataService
                .materials()
                .find((b) => b.id === thing.materialId);
              if (!groups[key]) {
                groups[key] = {
                  ...thing,
                  customerName: customer
                    ? customer.hasOwnProperty("name")
                      ? customer.name
                      : ""
                    : thing.customerId === 0
                    ? "Pərakəndə"
                    : "",
                  materialName: material.hasOwnProperty("name")
                    ? material.name
                    : "",
                  unit: material.unit,
                  sum: +parseFloat((thing.quantity * thing.price).toFixed(3)),
                  dateAdded: thing.transactionId.dateAdded,
                  details: thing.transactionId.details,
                };
              } else {
                groups[key].materialName +=
                  ", " +
                  this._dataService
                    .materials()
                    .find((c) => c.id === thing.materialId)?.name;
                groups[key].sum += parseFloat(
                  (thing.quantity * thing.price).toFixed(3)
                );
                groups[key].quantity = null;
                groups[key].price = null;
              }

              return groups;
            }, {});

            return Object.values(groupedThings);
          }),
          map(
            (
              data: { income: number; outgoings: number; [key: string]: any }[]
            ) => {
              this.performance = performance.now() - start;
              this.totalIn = data
                .map((t) => t.sum)
                .filter((x) => x >= 0)
                .reduce((acc, value) => acc + value, 0);
              this.totalOut = data
                .map((t) => t.sum)
                .filter((x) => x <= 0)
                .reduce((acc, value) => acc + value, 0);
              this._dataService.setLS("stockTableData", data.slice(-100));
              this._dataService.setLS("stockTotalIn", this.totalIn);
              this._dataService.setLS("stockTotalOut", this.totalOut);
              return data;
            }
          ),
          tap(() => {
            setTimeout(() => {
              this.dataLoading.set(false);
              if (this.searchInput && this.searchInput.nativeElement.value) {
                this.applyFilter(this.searchInput.nativeElement.value);
              }
            }, 500);
          })
        )
      ),
      takeUntil(this.componentDestroyed$)
    );

    return merge(localObservable, liveQueryObservable);
  }

  async getDxTransactions(startDate: Date, endDate: Date, stockGroupId?) {
    const result = await db.stock
      .where("date")
      .between(startDate, endDate, true, true)
      .toArray()
      .then((transaction) =>
        transaction.filter(
          (t) => t.stockGroupId == stockGroupId && t.tType != "Production"
        )
      );

    if (this.showSummary) {
      let stockCards = await this._stockService.getStockCards(
        startDate,
        endDate,
        stockGroupId
      );
      this.stockGraphs = stockCards;
      this.totalRemaining = 0;
      this.totalRemaining = stockCards
        .map(
          (stock) =>
            (stock.totalQuantityBeforeDate + stock.totalQuantity) *
            stock.unitPrice
        )
        .reduce((total, current) => total + current, 0);
      this._dataService.setLS("stockTotalRemaining", this.totalRemaining);
      this.stockGraphsUnfiltered = stockCards;
    } else {
      this.stockGraphs = [];
    }

    return result;
  }

  applyFilter(val) {
    this.gridApi.setGridOption("quickFilterText", val);
    this.totalIn = 0;
    this.totalOut = 0;
    this.gridApi.forEachNodeAfterFilterAndSort((node) => {
      this.totalIn += node.data.sum > 0 ? node.data.sum : 0;
      this.totalOut += node.data.sum < 0 ? node.data.sum : 0;
    });
    this.stockGraphs = this.stockGraphsUnfiltered.filter(function (o: any) {
      return (
        latinize(o.materialName.replace(/\s/g, "").toLowerCase()).indexOf(
          latinize(val.replace(/\s/g, "").toLowerCase())
        ) > -1
      );
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.setGridOption("includeHiddenColumnsInQuickFilter", true);
    var defaultSortModel: ColumnState[] = [
      { colId: "date", sort: "asc", sortIndex: 0 },
    ];
    // params.api.setDefaultColDef({
    //   // filter: true
    // });
    params.api.applyColumnState({ state: defaultSortModel });
  }

  async openAddStockDialog(
    data?,
    stockEntryType?: string,
    event?,
    editMode?: boolean,
    copyMode?: boolean
  ) {
    if ((event && event.event.target.innerText != "more_horiz") || !event) {
      this._dataService.showContextMenu = false;
      let transactions = null;
      if (data?.tId) {
        transactions = await db.stock.where("tId").equals(data.tId).toArray();
        data.editMode = editMode;
        data.copyMode = copyMode;
      }
      if (stockEntryType == "Stock Transfer") {
        this.openStockTransferDialog(data);
        return;
      }
      this.dialog.open(AddStockDialogComponent, {
        width: "1000px",
        data: {
          data,
          stockEntryType,
          stockGroupId: this.selectedStockGroupId(),
          transactions,
        },
        panelClass: "materialDialog",
        closeOnNavigation: true,
        disableClose: this._dataService.mobileDevice
          ? false
          : editMode || copyMode || !data?.tId
          ? true
          : false,
      });
    }
  }

  openStockTransferDialog(data?) {
    let dialogData = {
      selectedStockGroupId: this.selectedStockGroupId(),
      data: data ? data : null,
    };
    const dialogRef = this.dialog.open(StockTransferComponent, {
      width: "700px",
      maxHeight: "700px",
      data: dialogData,
      panelClass: "materialDialog",
      closeOnNavigation: true,
      disableClose: this._dataService.mobileDevice
        ? false
        : data
        ? false
        : true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      // this.getDxData();
      if (res) {
        this._syncService.syncDB();
        // console.log(res);
        // db.bank.add(res, res.id);
      }
    });
  }

  materialDetailsDialog(materialId) {
    let dialogRef, data;
    if (materialId) {
      data = this.materials.find((x) => x.id == materialId);
    }
    dialogRef = this.dialog.open(NewMaterialDialogComponent, {
      width: "750px",
      data: data,
      panelClass: "materialDialog",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (res?.id) {
          _.set(_.find(this.materials, { id: res.id }), "name", res.name);
          _.set(_.find(this.materials, { id: res.id }), "unit", res.unit);
          _.set(_.find(this.materials, { id: res.id }), "price", res.price);
          _.set(_.find(this.groupSumData, { id: res.id }), "name", res.name);
          _.set(_.find(this.groupSumData, { id: res.id }), "unit", res.unit);
        } else {
          this.materials = [...this.materials, res];
        }
        localStorage.setItem("groupSumData", JSON.stringify(this.groupSumData));
        localStorage.setItem("materials", JSON.stringify(this.materials));
      }
    });
  }

  onWheel(event: WheelEvent): void {
    if (
      !this.cardsContainer?.nativeElement?.className?.includes("expandedGraphs")
    ) {
      // Check if the event is triggered by a mouse wheel
      if (event.deltaMode === WheelEvent.DOM_DELTA_PIXEL) {
        this.cardsContainer.nativeElement.scrollLeft += event.deltaY;
        // event.preventDefault();
      }
    }
  }

  onCellMouseOver(params) {
    if (!this._dataService.showContextMenu)
      this.contextMenuData.data = params.data;
    this.hoverData = params.data;
  }

  onRightClick(event?) {
    event.preventDefault();
    if (this.contextMenuData.data != this.hoverData) {
      this.contextMenuData.data = this.hoverData;
    }
    const targetElement = event.target as HTMLElement;
    const rect = targetElement.getBoundingClientRect();

    this.contextMenuData.x = event.clientX;
    this.contextMenuData.y = rect.bottom + window.scrollY;

    this._dataService.showContextMenu = true;
  }

  contextMenu(data) {
    let lastOpened = this.contextMenuData.data
      ? this.contextMenuData.data.id
      : null;
    data.event.preventDefault();
    const targetElement = data.event.target as HTMLElement;
    const rect = targetElement.getBoundingClientRect();

    this.contextMenuData = {
      x: null,
      y: rect.bottom + window.scrollY,
      data: data.data,
    };
    this._dataService.showContextMenu =
      lastOpened == this.contextMenuData.data.id
        ? !this._dataService.showContextMenu
        : true;
  }

  async deleteEntry(row) {
    this._dataService.showContextMenu = false;
    let data = "";
    await db.stock
      .where("tId")
      .equals(row.tId)
      .toArray()
      .then((res) => {
        console.log(res);
        res.forEach((st) => {
          let materialName = this._dataService
            .materials()
            .filter((x) => x.id == st.materialId)[0].name;
          data += `${materialName} , ${st.quantity} əd \n `;
        });
      });
    data = data.substring(0, data.length - 3);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: data,
      closeOnNavigation: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.http
          .put(
            this._dataService.dbBase +
              "api.php/records/transactions/" +
              row.tId,
            { status: 1 }
          )
          .subscribe(async () => {
            await db.transactions.delete(row.tId);
            await db.stock.where("tId").equals(row.tId).delete();
            this._pusher.postData("Stock Entry Deleted").subscribe();
            this.toastr.success("", "Silindi!", {
              toastClass: "ngx-toastr customToast",
              progressBar: true,
              timeOut: 2500,
              progressAnimation: "increasing",
            });
            setTimeout(() => {
              // this.dataLoading = false;
              // this.loadingProgress = 0;
            }, 700);
          });
      }
    });
  }
}
