<app-navbar
  [headerText]="'Müştərilər'"
  [buttons]="navButtons"
  [dataLoading]="_customersService.dataLoading()"
></app-navbar>

<div class="container-fluid px-3">
  <div class="row mt-6">
    <div id="targetTable" class="col-md-6" style="position: relative">
      <div class="input-group">
        <div
          class="input-group-text"
          style="
            background-color: var(--primary-background);
            color: white;
            padding-inline: 20px;
            font-family: 'Mulish';
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            cursor: pointer;
          "
          (click)="searchInput.focus()"
        >
          <span class="material-symbols-outlined"> search </span>
        </div>
        <input
          #searchInput
          type="search"
          class="form-control"
          (keyup)="applyFilter(searchInput.value)"
          (search)="applyFilter(searchInput.value)"
          placeholder="Axtar"
        />
        @if (customerGroupCategoryIds.length>0) {
        <div
          style="
            position: absolute;
            right: 60px;
            display: flex;
            gap: 5px;
            height: 23px;
            top: 7px;
            cursor: no-drop;
          "
        >
          @for (item of customerGroups; track item.id) { @if(
          customerGroupCategoryIds.includes(item.id)) {
          <span
            (click)="item.filter = false; filterChanged()"
            class="chipBox"
            >{{ item.name }}</span
          >
          } }
        </div>
        }

        <div class="input-group-append d-flex">
          <div
            matTooltip="Filter"
            matTooltipClass="line-broken-tooltip roundedTooltip"
            matTooltipPosition="right"
            style="cursor: pointer"
            class="input-group-text"
            (click)="showFilterOptions = !showFilterOptions"
          >
            <span class="material-symbols-outlined"> filter_alt </span>
          </div>
        </div>
      </div>

      <div
        class="overlay"
        style="
          position: fixed;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.073);
          width: 100%;
          height: 100%;
          z-index: 1000;
        "
        *ngIf="showFilterOptions"
        (click)="showFilterOptions = false"
      ></div>
      <section
        class="filterTable"
        style="z-index: 1001; font-family: Mulish"
        *ngIf="showFilterOptions"
        [@slideInOut]="showFilterOptions ? 'in' : 'out'"
      >
        <div
          class="px-3 py-1"
          style="
            background-color: #0000000a;
            display: flex;
            font-family: 'Mulish';
            font-weight: 600;
            font-size: 18px;
          "
        >
          <span style="padding-left: 23px">Filter</span>
        </div>
        @for (item of customerGroups; track item.id) {
        <div
          class="d-flex gap-2 align-items-center cardHover px-3 py-2"
          (click)="item.filter = !item.filter; filterChanged()"
          style="cursor: pointer; white-space: nowrap"
        >
          <div class="box">
            <mat-icon
              style="
                color: var(--primary-background);
                font-size: 20px;
                line-height: 25px;
              "
              *ngIf="item.filter"
              >check</mat-icon
            >
          </div>
          {{ item.name }}
        </div>
        }
        <div class="px-3 py-2" style="background-color: #0000000a">
          <button
            class="btn btnDelete w-100"
            style="
              padding: 4px !important;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
              min-width: 6rem;
            "
            (click)="clearFilterSelections()"
          >
            <span class="material-symbols-outlined"> remove_selection </span
            >Təmizlə
          </button>
          <button
            class="btn btnDelete w-100"
            style="
              padding: 4px !important;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
              min-width: 6rem;
            "
            (click)="showFilterOptions = false"
          >
            <span class="material-symbols-outlined"> close </span> Bağla
          </button>
        </div>
      </section>
    </div>
    <div class="col-md-6 mt-smm-6">
      <mat-chip-listbox aria-label="Customer Types">
        <mat-chip-option
          selected
          (click)="setChipSelected($event.target.innerText)"
          >Tərəf-Müqabillər</mat-chip-option
        >
        <mat-chip-option (click)="setChipSelected('Debitorlar')"
          >Debitorlar
          <span class="balanceSums">{{
            _customersService.sumDebit | number : "1.0-0" | commaToSpace
          }}</span>
          <span
            class="manat"
            style="font-size: 10px !important; text-align: center"
            >₼</span
          ></mat-chip-option
        >
        <mat-chip-option (click)="setChipSelected('Kreditorlar')"
          >Kreditorlar
          <span class="balanceSums">{{
            (-_customersService.sumKredit | number : "1.0-0" | commaToSpace) ||
              0
          }}</span>
          <span
            class="manat"
            style="font-size: 10px !important; text-align: center"
            >₼</span
          ></mat-chip-option
        >
      </mat-chip-listbox>
      <!-- <div class="summary">
        <span>Alınan {{ _bankService.totalIn | number }} <span class="manat">₼</span></span>
        <span>Ödənən {{ _bankService.totalOut | number }} <span class="manat">₼</span></span>
        <span>Fərq {{ _bankService.totalIn - _bankService.totalOut | number }} <span class="manat">₼</span></span>
      </div> -->
    </div>

    <ag-grid-angular
      style="
        width: 100%;
        height: calc(100vh - 130px);
        border-radius: 6px;
        overflow: hidden;
        margin-top: 1rem;
      "
      class="ag-theme-alpine"
      [rowData]="agGridData$ | async"
      [columnDefs]="columnDefs"
      [localeText]="_dataService.agGridLocale"
      (gridReady)="onGridReady($event)"
      (rowClicked)="_customersService.customerDialog($event.data)"
      [cacheQuickFilter]="true"
      [isExternalFilterPresent]="isExternalFilterPresent"
      [doesExternalFilterPass]="doesExternalFilterPass"
    >
    </ag-grid-angular>
  </div>
</div>
