import { Component, inject, signal, effect, HostListener } from "@angular/core";
import { DataService } from "src/app/data.service";
import { StockService } from "../stock/stock.service";

@Component({
  selector: "app-material",
  templateUrl: "./material.component.html",
  styleUrl: "./material.component.css",
})
export class MaterialComponent {
  _stockService = inject(StockService);
  _dataService = inject(DataService);
  selectedStockGroupId = signal(100);
  dataLoading: boolean = false;
  ngHeader = {
    items: [
      { id: 100, name: "Materiallar" },
      ...this._dataService.stockGroups(),
    ],
    selectedItem: this.selectedStockGroupId(),
  };
  navButtons = [
    {
      name: "Yeni Material",
      icon: "add",
      function: () => this._stockService.materialDialog(),
    },
  ];
  columnDefs = [
    {
      headerName: "id",
      field: "materialId",
      hide: true,
    },
    {
      headerName: "Material",
      field: "materialName",
      sortable: true,
      flex: 2,
      minWidth: 150,
      filterValueGetter: (params) =>
        this._dataService.latinizeText(params, "materialName"),
    },
    {
      headerName: "Qiymət, ₼",
      field: "unitPrice",
      sortable: true,
      flex: 2,
      minWidth: 150,
      cellRenderer: (params) => {
        return params.value.toFixed(2).replace(/[.,]00$/, "");
      },
    },
    {
      headerName: "Qalıq, kg",
      field: "totalQuantity",
      sortable: true,
      flex: 2,
      minWidth: 150,
      cellRenderer: (params) => {
        return params.value.toFixed(2).replace(/[.,]00$/, "");
      },
    },
    {
      headerName: "Qalıq, ₼",
      field: "totalPrice",
      sortable: true,
      flex: 2,
      minWidth: 150,
      cellRenderer: (params) => {
        return params.value.toFixed(2).replace(/[.,]00$/, "");
      },
      aggFunc: "sum",
      sort: 'desc'
    },
  ];
  agGridData$;

  constructor() {
    effect(() => {
      this.selectedStockGroupId();
      this._dataService.dateChangedSig();
      this.agGridData$ = this._stockService.getMaterialTotals(
        this._dataService.endDate.value.format("YYYY-MM-DD"),
        this.selectedStockGroupId() ?? 0
      );
    });
  }
}
