import {
  Component,
  Inject,
  OnInit,
  Optional,
  ViewChild,
  inject,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import "lodash";
import { DataService } from "src/app/data.service";
declare var _: any;
import { db } from "src/app/db.service";
import { CustomersService } from "src/app/customers/customers.service";
import { ColumnState, GridReadyEvent } from "ag-grid-community";
import { AuthService } from "src/app/auth.service";

@Component({
  selector: "app-customer-dialog",
  templateUrl: "./customer-dialog.component.html",
  styleUrls: ["./customer-dialog.component.css"],
})
export class NewCustomerDialogComponent implements OnInit {
  _authService = inject(AuthService);
  selectedCustomerGroupId: number = this._dataService.customerGroups().length
    ? this._dataService.customerGroups()[0].id
    : null;
  @ViewChild("inv") form;
  dataLoading: boolean = false;
  customerName: string;
  details: string;
  addBalance: string;
  initialBalance: number = 0;
  customerBalance: number = 0;
  customerBalanceEdited: number = 0;
  showOperations: boolean = false;
  agGridData$;
  columnDefs = [
    {
      headerName: "id",
      field: "tId",
      hide: true,
    },
    {
      headerName: "Əməliyyat Tipi",
      field: "type",
      flex: 3,
      minWidth: 100,
      cellRenderer: (params) => {
        switch (params.value) {
          case "Bank":
            return params.data.sum < 0 ? "Kassa Məxaric" : "Kassa Mədaxil";
          case "Return":
            return "Qaytarma";
          case "Sells":
            return "Satış";
          case "Stock Entry":
            return "Anbar Giriş";
          case "Stock Out":
            return "Anbar Çıxış";
          case "Production":
            return "İstehsalat";
          case "Salary":
            return "Maaş";
          default:
            return params.value;
        }
      },
    },
    {
      headerName: "Məbləğ",
      field: "sum",
      flex: 3,
      minWidth: 100,
      cellRenderer: (params) =>
        params.value < 0 ? -params.value : params.value,
    },
    {
      headerName: "Tarix",
      field: "date",
      flex: 3,
      minWidth: 100,
      sort: "desc"
    },
  ];
  gridApi: any;
  constructor(
    public dialogRef: MatDialogRef<NewCustomerDialogComponent>,
    private http: HttpClient,
    private toastr: ToastrService,
    public _dataService: DataService,
    protected _customersService: CustomersService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    // effect(() => {
    //   this._dataService.customers();
    //   // this.calculateBalance();
    // });
  }

  async ngOnInit() {
    if (this.dialogData?.data?.name) {
      let customerDetails = this._dataService
        .customers()
        .find((c) => c.id == this.dialogData.data.id);
      this.selectedCustomerGroupId = customerDetails.customerGroupId;
      this.details = customerDetails.details;
      this.customerName = this.dialogData.data.name;
      this.initialBalance = customerDetails.balance || 0;
    }

    if (this.dialogData?.data?.customerGroupId) {
      this.selectedCustomerGroupId = this.dialogData?.data?.customerGroupId;
    }
    if (this.dialogData?.data?.customerGroupId && !this.dialogData.data.id) {
      this.dialogData.data = null;
    }
    if (this.dialogData.data?.customerData?.finalBalance) {
      this.customerBalance = this.dialogData.data.customerData.finalBalance;
      this.customerBalanceEdited =
        this.dialogData.data.customerData.finalBalance;
    } else if (
      this.dialogData?.data?.name &&
      !this.dialogData.data?.customerData?.finalBalance
    ) {
      (
        await this._customersService.getCustomersBalanceById(
          this.dialogData.data.id
        )
      ).subscribe((r) => {
        let customerBalaceObj = r.find(
          (c) => c.customerId == this.dialogData.data.id
        );
        this.customerBalance = customerBalaceObj.finalBalance;
        this.customerBalanceEdited = customerBalaceObj.finalBalance;
      });
    }
  }

  addCustomer(formData, event) {
    event.srcElement.disabled = true;
    event.target.innerText = "Gözləyin ...";
    // CHECK IF DATA ALREADY EXISTS IN TABLE
    if (
      _.filter(this._dataService.customers(), (a) => {
        return a.name.toLowerCase() == formData.name.toLowerCase();
      }).length &&
      !this.dialogData?.data
    ) {
      this.toastr.error("", "Bu adda müştəri mövcuddur!", {
        toastClass: "ngx-toastr",
        progressBar: true,
        timeOut: 2500,
        progressAnimation: "increasing",
      });

      event.target.innerText = "Əlavə et";
      event.target.disabled = false;

      return;
    }

    let httpData = this.dialogData?.data?.name
      ? this.http.put(
          this._dataService.dbBase +
            "api.php/records/customers/" +
            this.dialogData?.data.id,
          formData
        )
      : this.http.post(
          this._dataService.dbBase + "api.php/records/customers",
          formData
        );

    formData.details = formData.details ? formData.details : "";

    return httpData.subscribe({
      next: (res) => {
        formData.id = res;

        if (this.dialogData?.data?.name) {
          formData.id = this.dialogData.data.id;
          const index = this._dataService
            .customers()
            .findIndex((obj) => obj.id === this.dialogData.data.id);
          this._dataService.customers.set([...this._dataService.customers()]);
          // TODO THIS WAS SIGNAL MUTATE
          this._dataService.customers()[index] = {
            ...formData,
            balance: this.dialogData?.data?.balance || null,
          };

          this._dataService.customers.set([...this._dataService.customers()]);
          this.toastr.success("", "Yeniləndi!", {
            toastClass: "ngx-toastr customToast",
            progressBar: true,
            timeOut: 2500,
            progressAnimation: "increasing",
          });
        } else {
          formData.id = res;
          this._dataService.customers.set([
            ...this._dataService.customers(),
            formData,
          ]);
          this.toastr.success("", "Əlavə edildi!", {
            toastClass: "ngx-toastr customToast",
            progressBar: true,
            timeOut: 2500,
            progressAnimation: "increasing",
          });
        }
        this.dialogRef.close(formData);
        console.log(this._dataService.customers());
        this._dataService.setLS("customers", this._dataService.customers());
      },
      error: (err) => {
        console.log(err);
        this.dialogRef.close();
        this.toastr.error("", "Xəta baş verdi!", {
          toastClass: "ngx-toastr",
          progressBar: true,
          timeOut: 2500,
          progressAnimation: "increasing",
        });
      },
    });
  }

  async getCustomerGroups() {
    return await db.customerGroups.toArray();
  }

  setInitialBalance(type: string, btn, input) {
    let btnText = btn.innerText;
    btn.innerText = "Gözləyin..";
    btn.disabled = true;

    let cbEdited =
      type == "borc" ? -this.customerBalanceEdited : this.customerBalanceEdited;
    let addedBalance: number = cbEdited - this.customerBalance;
    let resultBalance = this.initialBalance + addedBalance;
    this.http
      .put(
        this._dataService.dbBase +
          "api.php/records/customers/" +
          this.dialogData?.data.id,
        { balance: resultBalance }
      )
      .subscribe({
        complete: () => {
          const index = this._dataService
            .customers()
            .findIndex((obj) => obj.id === this.dialogData.data.id);

          this._dataService.customers()[index].balance = resultBalance;
          this._dataService.customers.set([...this._dataService.customers()]);

          this.dialogData.data.balance = resultBalance;
          this.customerBalance =
            type == "borc"
              ? this.customerBalance - addedBalance
              : this.customerBalance + addedBalance;
          btn.innerText = btnText;
          btn.disabled = false;
          this.toastr.success("", "Uğurlu!", {
            toastClass: "ngx-toastr customToast",
            progressBar: true,
            timeOut: 2500,
            progressAnimation: "increasing",
          });
        },
        error: () => {
          this.toastr.error("", "Xəta baş verdi, yenidən cəhd edin", {
            toastClass: "ngx-toastr",
            progressBar: true,
            timeOut: 2500,
            progressAnimation: "increasing",
          });
        },
      });
  }

  async customerOperations(event) {
    if (this.showOperations) {
      this.showOperations = false;
      return;
    }
    this.dataLoading = true;
    this.agGridData$ = await this._customersService.getCustomerOperations(
      this.dialogData.data.id
    );
    this.dataLoading = false;
    this.showOperations = true;
  }

  transactionDetails(data) {
    console.log(data);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.setIncludeHiddenColumnsInQuickFilter(true);
    var defaultSortModel: ColumnState[] = [
      { colId: "date", sort: "desc", sortIndex: 0 },
    ];
    this.gridApi.setGetRowClass((params) => {
      return params.node.data.justAdded == true ? "tableDataLoading" : "";
    });
    // params.api.setDefaultColDef({
    //   // filter: true
    // });
    params.api.applyColumnState({ state: defaultSortModel });
  }

  // async calculateBalance() {
  //   let customerBalaceObj = this.dialogData.data.customerData
  //     ? this.dialogData.data.customerData
  //     : null;
  // if (!customerBalaceObj.customerName) {
  //   (await this._customersService.getCustomersBalance()).subscribe((r) => {
  //     customerBalaceObj = r.find(
  //       (c) => c.customerId == this.dialogData.data.id
  //     );
  //   });
  // }
  //   this.customerBalance = parseFloat(
  //     (
  //       customerBalaceObj.sellsTotal -
  //       customerBalaceObj.stocksTotal -
  //       customerBalaceObj.incomeTotal +
  //       customerBalaceObj.outgoingTotal +
  //       this._dataService
  //         .customers()
  //         .find((c) => c.id == this.dialogData.data.id).balance
  //     ).toFixed(2)
  //   );
  // }
}
