<app-navbar
  [ngHeader]="ngHeader"
  [buttons]="navButtons"
  [dataLoading]="_bankService.dataLoading()"
  (onChange)="
    _bankService._selectedCashboxId$.next($event);
    _bankService.selectedCashboxId.set($event);
    _dataService.setLS('selectedCashboxId', $event)
  "
></app-navbar>

<!-- @if(_bankService.selectedCashboxId()==0) {
<div class="mt-6 px-3" style="margin-bottom: -3rem">
  <app-cash-distribution></app-cash-distribution>
</div>
} -->

<div class="container-fluid px-3 mt-6">
  <div class="row">
    <div class="col-md-4" id="targetTable" style="position: relative">
      <div class="input-group">
        <div
          class="input-group-text"
          style="
            background-color: var(--primary-background);
            color: white;
            padding-inline: 20px;
            font-family: 'Mulish';
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            cursor: pointer;
          "
          (click)="searchInput.focus()"
        >
          <span class="material-symbols-outlined"> search </span>
        </div>
        <input
          #searchInput
          type="search"
          class="form-control cardHover"
          (keyup)="applyFilter(searchInput.value)"
          (search)="applyFilter(searchInput.value)"
          placeholder="Axtar"
          (focus)="searchInputFocused = true"
          (blur)="searchInputFocused = false"
        />
        <div class="input-group-append d-flex cardHover">
          <div
            matTooltip="Filter"
            matTooltipClass="line-broken-tooltip roundedTooltip"
            matTooltipPosition="right"
            style="cursor: pointer"
            class="input-group-text"
            (click)="showFilterOptions = !showFilterOptions"
          >
            <span class="material-symbols-outlined"> filter_alt </span>
          </div>
        </div>
      </div>
      <div
        class="overlay"
        style="
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1000;
          transition-duration: 0.2s;
        "
        [ngStyle]="{
          'background-color': showFilterOptions ? 'rgb(0 0 0 / 15%)' : 'initial'
        }"
        *ngIf="showFilterOptions || _dataService.showContextMenu"
        (click)="showFilterOptions = false"
      ></div>
      <section
        class="filterTable"
        *ngIf="showFilterOptions"
        style="z-index: 1001; font-family: Mulish"
        [@slideInOut]="showFilterOptions ? 'in' : 'out'"
      >
        <div
          class="px-3 py-1"
          style="
            background-color: var(--primary-background);
            color: white;
            display: flex;
            font-family: 'Mulish';
            font-weight: 600;
            font-size: 18px;
          "
        >
          <span style="padding-left: 23px">Filter</span>
        </div>
        <div style="display: grid; grid-template-columns: auto 1fr; gap: 1px">
          <!-- <div>
            <div
              class="d-flex gap-2 align-items-center cardHover px-3 py-2"
              (click)="item.filter = item.filter ? !item.filter : false; filterChanged()"
              style="
                cursor: pointer;
                white-space: nowrap;
                background-color: #f5f5f5;
                color: var(--primary-background);
                margin-top: -1px;
                border-bottom: 1px solid #d6d6d6;
              "
            >
              <div class="box">
                <mat-icon
                  style="
                    color: var(--primary-background);
                    font-size: 20px;
                    line-height: 25px;
                  "
                  >check</mat-icon
                >
              </div>
              Alınanlar
            </div>
          </div> -->
          <!-- <div>
            <div
              class="d-flex gap-2 align-items-center cardHover px-3 py-2"
              (click)="item.filter = !item.filter; filterChanged()"
              style="
                cursor: pointer;
                white-space: nowrap;
                background-color: #f5f5f5;
                color: var(--primary-background);
                margin-top: -1px;
                border-bottom: 1px solid #d6d6d6;
              "
            >
              <div class="box">
                <mat-icon
                  style="
                    color: var(--primary-background);
                    font-size: 20px;
                    line-height: 25px;
                  "
                  >check</mat-icon
                >
              </div>
              Ödənənlər
            </div>
          </div> -->
          <!-- <div>
            <div
              class="d-flex gap-2 align-items-center cardHover px-3 py-2"
              (click)="item.filter = !item.filter; filterChanged()"
              style="cursor: pointer; white-space: nowrap"
            >
              <div class="box">
                <mat-icon
                  style="
                    color: var(--primary-background);
                    font-size: 20px;
                    line-height: 25px;
                  "
                  >check</mat-icon
                >
              </div>
              Some sub category
            </div>
          </div> -->
          <div>
            @for (item of bankGroupCategories; track item.id) {
            <div
              class="d-flex gap-2 align-items-center cardHover px-3 py-2"
              (click)="item.filter = !item.filter; filterChanged()"
              style="cursor: pointer; white-space: nowrap"
            >
              <div class="box">
                <mat-icon
                  style="
                    color: var(--primary-background);
                    font-size: 20px;
                    line-height: 25px;
                  "
                  *ngIf="item.filter"
                  >check</mat-icon
                >
              </div>
              {{ item.name }}
            </div>
            }
          </div>
        </div>
        <!-- FILTER ITEMS -->
        <div class="px-3 py-2" style="background-color: #0000000a">
          <button
            class="btn btnDelete w-100"
            style="
              padding: 4px !important;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
            "
            (click)="clearFilterSelections()"
          >
            <span class="material-symbols-outlined"> remove_selection </span
            >Təmizlə
          </button>
          <button
            class="btn btnDelete w-100"
            style="
              padding: 4px !important;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
            "
            (click)="showFilterOptions = false"
          >
            <span class="material-symbols-outlined"> close </span> Bağla
          </button>
        </div>
      </section>
    </div>
    <div class="col-md-8 mt-smm-6">
      <div class="summaryContainer">
        <ng-container *ngIf="bankTotals$ | async as bank">
          <span
            class="summaryElementContainer cardHover c-pointer"
            routerLink="../CashDist"
            [queryParams]="{ selectedChip: 'income' }"
            ><span class="summaryType">Alınan</span>
            <span
              >{{ _bankService.totalIn | number : "1.0-0" | commaToSpace }}
              <span class="manat">₼</span></span
            ></span
          >
          <span
            class="summaryElementContainer cardHover c-pointer"
            routerLink="../CashDist"
            ><span class="summaryType">Ödənən</span>
            <span
              >{{ _bankService.totalOut | number : "1.0-0" | commaToSpace }}
              <span class="manat">₼</span></span
            ></span
          >
          <span class="summaryElementContainer"
            ><span class="summaryType">Fərq</span>
            <span
              >{{
                _bankService.totalIn - _bankService.totalOut
                  | number : "1.0-0"
                  | commaToSpace
              }}
              <span class="manat">₼</span></span
            ></span
          >
          <span class="summaryElementContainer"
            ><span class="summaryType">Qalıq</span>
            <span
              >{{
                _bankService.kapitalBalance
                  ? _bankService.kapitalBalance
                  : (bank.in - bank.out + bank.residue
                    | number : "1.2-2"
                    | commaToSpace)
              }}
              <span class="manat">₼</span></span
            ></span
          >
        </ng-container>
      </div>
    </div>
  </div>

  @if (selectedBankGroups.length>0) {
  <div class="row">
    <div
      style="
        display: flex;
        gap: 5px;
        height: 23px;
        cursor: no-drop;
        margin-top: 0.5rem;
        margin-bottom: -0.5rem;
      "
    >
      <!-- SELECTED FILTERS CHIPS -->
      @for (item of bankGroupCategories; track item.id) {
      @if(_dataService.findObjectById(item.id, bankGroupCategories)?.filter) {
      <span (click)="item.filter = false; filterChanged()" class="chipBox">{{
        item.name
      }}</span>
      } }
    </div>
  </div>

  }

  <ag-grid-angular
    style="
      width: 100%;
      border-radius: 6px;
      overflow: hidden;
      margin-top: 1rem;
      height: calc(100vh - 130px);
    "
    class="ag-theme-alpine cardHover"
    [rowData]="agGridData$ | async"
    [columnDefs]="columnDefs"
    [animateRows]="false"
    [localeText]="_dataService.agGridLocale"
    (gridReady)="onGridReady($event)"
    (rowClicked)="openBankDialog('', $event.data.tId, $event.data, $event)"
    (contextmenu)="onRightClick($event)"
    (cellMouseOver)="onCellMouseOver($event)"
    [getRowId]="getRowId"
    [cacheQuickFilter]="true"
    [isExternalFilterPresent]="isExternalFilterPresent"
    [doesExternalFilterPass]="doesExternalFilterPass"
  >
  </ag-grid-angular>

  <div
    class="context-menu filterSettings"
    *ngIf="_dataService.showContextMenu"
    [style.right.px]="contextMenuData.x ? auto : 20"
    [style.left.px]="
      !contextMenuData.x
        ? auto
        : _dataService.showSideNav
        ? contextMenuData.x - 170
        : contextMenuData.x - 50
    "
    [style.top.px]="contextMenuData.y"
  >
    <div
      class="px-3 py-1"
      style="
        background-color: #0000000a;
        display: flex;
        font-family: 'Mulish';
        font-weight: 600;
        font-size: 17px;
        justify-content: center;
      "
    >
      <span>Əməliyyatlar</span>
    </div>
    <div class="filterItems">
      <button
        class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
        (click)="
          openBankDialog(
            '',
            contextMenuData.data.tId,
            contextMenuData.data,
            contextMenuData.data.event,
            true
          );
          _dataService.showContextMenu = false
        "
      >
        <span class="material-symbols-outlined"> edit </span>
        <span class="w-100">Düzəliş et</span>
      </button>
      <button
        class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
        (click)="
          openBankDialog(
            '',
            contextMenuData.data.tId,
            contextMenuData.data,
            contextMenuData.data.event,
            true,
            true
          );
          _dataService.showContextMenu = false
        "
      >
        <span class="material-symbols-outlined"> content_copy </span>
        <span class="w-100">Kopyala</span>
      </button>
      <button
        class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
        (click)="deleteEntry(contextMenuData.data)"
      >
        <span class="material-symbols-outlined"> delete </span>
        <span class="w-100">Sil</span>
      </button>
    </div>
  </div>
</div>
