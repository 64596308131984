import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  inject,
  computed,
  effect,
  HostListener,
} from "@angular/core";
import { DataService } from "../data.service";
import { BankService } from "./bank.service";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";

import { BankDialogComponent } from "./bank-dialog/bank-dialog.component";
import { TransferBankDialogComponent } from "../dialogs/transfer-bank-dialog/transfer-bank-dialog.component";
import { ConfirmationDialogComponent } from "../shared/confirmation-dialog/confirmation-dialog.component";

import { db } from "src/app/db.service";
import {
  ColumnState,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from "ag-grid-community";

import * as _moment from "moment";
import { PusherService } from "../pusher.service";
import { slideAnimation } from "../shared/fade.animation";
import { SalaryDialogComponent } from "./salary-dialog/salary-dialog.component";
const moment = _moment;

@Component({
  selector: "app-bank",
  templateUrl: "./bank.component.html",
  styleUrls: ["./bank.component.css"],
  animations: [slideAnimation],
})
export class BankComponent {
  _bankService = inject(BankService);
  _dataService = inject(DataService);
  _pusher = inject(PusherService);
  http = inject(HttpClient);
  dialog = inject(MatDialog);
  toastr = inject(ToastrService);

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this._dataService.showContextMenu = false;
      this.showFilterOptions = false;
    }
  }

  bankGroupCategories = this._dataService
    .bankGroupCategories()
    .map((category) => ({ ...category, filter: false }));
  searchInputFocused: boolean = false;
  contextMenuData = { x: 0, y: 0, data: null };
  hoverData: any;
  showFilterOptions: boolean = false;
  cashbox = computed(() => {
    return [
      { id: 0, name: "Ümumi Kassa" },
      ...this._dataService.cashBox(),
      { id: 100, name: "Kapital Bank online" },
    ];
  });
  bankTotals$;
  selectedBankGroups = [];

  @ViewChild("searchInput") searchInput: ElementRef;
  dateNow = moment(Date.now()).format("YYYY-MM-DD");

  protected gridApi!: GridApi;
  navButtons = [
    {
      name: "Mədaxil",
      icon: "add",
      function: () => this.openBankDialog("medaxil"),
    },
    {
      name: "Məxaric",
      icon: "remove",
      function: () => this.openBankDialog(),
    },
    {
      name: "Köçürmə",
      icon: "move_down",
      function: () => this.openTransferBankDialog(),
    },
    {
      name: "Maaş",
      icon: "price_change",
      function: () => this.openSalaryDialog(),
    },
  ];

  ngHeader = {
    items: this.cashbox(),
    selectedItem: this._bankService.selectedCashboxId(),
  };

  columnDefs = [
    {
      headerName: "id",
      field: "tId",
      hide: true,
    },
    {
      headerName: "Tipi",
      field: "bankGroup",
      flex: 2,
      minWidth: 100,
      cellRenderer: (params) => {
        let result;
        if (params.data.bankGroupId && params.data.bankGroupId == "loading") {
          result = '<div class="lds-dual-ring tableRing"></div>';
        } else if (params.data.bankGroupId || params.data.bankGroupId == 0) {
          result = params.data.bankGroup;
        } else {
          result = "Köçürmə";
        }
        if (params.data.refersTo) {
          result += "(r)";
        }
        return result;
      },
      filterValueGetter: (params) =>
        this._dataService.latinizeText(params, "bankGroup"),
    },
    {
      headerName: "Tərəf Müqabil",
      field: "customerName",
      sortable: true,
      flex: 3,
      minWidth: 150,
      cellRenderer: (params) => {
        if (params.data.customerName) {
          return params.data.customerName;
        } else {
          return this._dataService
            .cashBox()
            .find((c) => c.id == params.data.cashboxId)
            ? this._dataService
                .cashBox()
                .find((c) => c.id == params.data.cashboxId).name
            : "Köçürmə";
        }
      },
      cellClass: (params) => {
        return params.value.length > 30 ? "justify-content-start clipText" : "";
      },
      filterValueGetter: (params) =>
        this._dataService.latinizeText(params, "customerName"),
      // cellStyle: {'background-color': '#ececec'},
    },
    {
      headerName: "Alınan",
      field: "income",
      sortable: true,
      flex: 2,
      minWidth: 120,
      cellRenderer: (params) => {
        return params.value
          ? params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          : "";
      },
      cellStyle: { "word-spacing": "-2px !important", "white-space": "nowrap" },
    },
    {
      headerName: "Ödənən",
      field: "outgoings",
      sortable: true,
      flex: 2,
      minWidth: 120,
      cellRenderer: (params) => {
        return params.value
          ? params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          : "";
      },
      cellStyle: { "word-spacing": "-2px !important", "white-space": "nowrap" },
    },
    {
      headerName: "Tarix",
      field: "date",
      flex: 2,
      minWidth: 120,
      sortable: true,
      comparator: (dateA, dateB, nodeA, nodeB, isInverted) => {
        if (dateA === dateB) {
          // If dates are equal, sort by id (descending)
          const idA = nodeA.data.id;
          const idB = nodeB.data.id;
          return idA > idB ? -1 : idA < idB ? 1 : 0;
        } else {
          // Sort by date (descending)
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        }
      },
      cellRenderer: (params) => {
        return params.value.split("-").reverse().join("-");
      },
    },
    {
      headerName: "Məlumat",
      field: "details",
      flex: 3,
      minWidth: 100,
      cellClass: (params) => {
        return "justify-content-start clipText";
      },
    },
    {
      headerName: "",
      field: "delete",
      flex: 1,
      maxWidth: 50,
      cellRenderer: () => {
        return `<span class='d-flex align-items-center h-100'><i class='material-icons'>more_horiz</i></span>`;
      },
      onCellClicked: (params) => {
        this.contextMenu(params);
      },
    },
  ];
  public getRowId: GetRowIdFunc = (params) => params.data.id;
  agGridData$;

  constructor() {
    effect(async () => {
      if (this._dataService.customers() || this._dataService.dbChanged()) {
        this.getData();
      }
    });
  }

  getData() {
    this.cashbox = this._dataService.cashBox();
    this._bankService._selectedCashboxId$.next(
      this._bankService.selectedCashboxId()
    );
    this.agGridData$ = this._bankService.getBankTableData();
    this.bankTotals$ = this._bankService.getTotals(
      this._dataService.startDate.value.format("YYYY-MM-DD"),
      this._dataService.endDate.value.format("YYYY-MM-DD"),
      this._bankService.selectedCashboxId()
    );
    filter = false;
  }

  applyFilter(val) {
    this.gridApi.setGridOption("quickFilterText", val);
    this._bankService.totalIn = 0;
    this._bankService.totalOut = 0;
    this.gridApi.forEachNodeAfterFilterAndSort((node) => {
      this._bankService.totalIn += node.data.income;
      this._bankService.totalOut += node.data.outgoings;
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.setGridOption("includeHiddenColumnsInQuickFilter", true);
    var defaultSortModel: ColumnState[] = [
      { colId: "date", sort: "asc", sortIndex: 0 },
    ];
    // this.gridApi.setGetRowClass((params) => {
    //   return params.node.data.justAdded == true ? "tableDataLoading" : "";
    // });
    // params.api.setDefaultColDef({
    //   // filter: true
    // });
    params.api.applyColumnState({ state: defaultSortModel });
  }

  filterChanged() {
    let bankGroupCategoryIds = this.bankGroupCategories
      .filter((category) => category.filter)
      .map((category) => category.id);

    bankGroups = this._dataService
      .bankGroups()
      .filter((category) =>
        bankGroupCategoryIds?.includes(category.bankGroupCategory)
      )
      .map((category) => category.id);
    this.selectedBankGroups = this._dataService
      .bankGroups()
      .filter((category) =>
        bankGroupCategoryIds?.includes(category.bankGroupCategory)
      )
      .map((category) => category.id);
    filter = bankGroups.length > 0 ? true : false;
    this.gridApi.onFilterChanged();

    this._bankService.totalIn = 0;
    this._bankService.totalOut = 0;
    this.gridApi.forEachNodeAfterFilterAndSort((node) => {
      this._bankService.totalIn += node.data.income;
      this._bankService.totalOut += node.data.outgoings;
    });
  }

  isExternalFilterPresent(): boolean {
    return filter !== false;
  }

  doesExternalFilterPass(node: IRowNode<any>): boolean {
    if (node.data) {
      return bankGroups?.includes(node.data.bankGroupId);
    }
    return true;
  }

  clearFilterSelections() {
    this.bankGroupCategories.forEach((b) => (b.filter = false));
    filter = false;
    this.filterChanged();
  }

  deleteEntry(row) {
    this._dataService.showContextMenu = false;
    let sum = row.income ? row.income : row.outgoings;
    let data =
      row.customerName +
      ` - ` +
      sum.toFixed(3).replace(/\.(\d\d)\d?$/, ".$1") +
      " ₼";
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: data,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this._bankService.dataLoading.set(true);
        this.http
          .put(
            this._dataService.dbBase +
              "api.php/records/transactions/" +
              row.tId,
            { status: 1 }
          )
          .subscribe(async () => {
            await db.transactions.delete(row.tId);
            await db.bank.where("tId").equals(row.tId).delete();
            let gridRow = this.gridApi.getRowNode(row.id);
            this.gridApi.applyTransaction({
              remove: [gridRow],
            });
            this._pusher.postData("Bank Entry Deleted").subscribe();
            this.toastr.success("", "Silindi!", {
              toastClass: "ngx-toastr customToast",
              progressBar: true,
              timeOut: 2500,
              progressAnimation: "increasing",
            });
            this._bankService.dataLoading.set(false);
          });
      }
    });
  }

  openBankDialog(
    type?: string,
    transactionId?: number,
    rowData?,
    event?,
    editMode?: boolean,
    copyMode?: boolean
  ) {
    if ((event && event.event.target.innerText != "more_horiz") || !event) {
      this._dataService.showContextMenu = false;

      if (rowData && rowData.transaction?.type == "Cashbox Transfer") {
        this.openTransferBankDialog(rowData);
        return;
      }
      if (rowData && rowData.transaction?.type == "Salary") {
        let dialogData = {
          cashboxId: this._bankService.selectedCashboxId(),
          transactionId: transactionId,
        };
        this.openSalaryDialog(dialogData);
        return;
      }
      let dialogData: {
        type?: string;
        data?: Object;
        cashboxId?: number;
        editMode?: boolean;
        copyMode?: boolean;
      } = {};
      dialogData.type = type;
      dialogData.cashboxId = this._bankService.selectedCashboxId();

      if (rowData) {
        rowData.bankGroupName = rowData.bankGroup;
        rowData.transactionId = rowData.tId;
        dialogData.type = rowData.income ? "medaxil" : "";
        dialogData.cashboxId = rowData.cashboxId || 1;
        dialogData.data = rowData;
        dialogData.editMode = editMode;
        dialogData.copyMode = copyMode;
      }

      const dialogRef = this.dialog.open(BankDialogComponent, {
        width: "800px",
        maxHeight: "700px",
        data: dialogData,
        closeOnNavigation: true,
        disableClose: this._dataService.mobileDevice
          ? false
          : editMode || copyMode || !transactionId
          ? true
          : false,
        panelClass: "materialDialog",
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          const startDate = new Date(
            this._dataService.startDate.value.format("YYYY-MM-DD")
          );
          const endDate = new Date(
            this._dataService.endDate.value.format("YYYY-MM-DD")
          );
          const targetDate = new Date(res.date);
          if (targetDate < startDate || targetDate > endDate) {
            this.gridApi.applyTransaction({
              add: [
                {
                  ...res,
                  bankGroup: res.bankGroupName,
                  transactionId: res.tId,
                  transaction: res.transactionId,
                  // justAdded: true,
                },
              ],
            });
            if (res.remove) {
              let row = this.gridApi.getRowNode(res.remove);
              console.log(row);
              this.gridApi.applyTransaction({
                remove: [row],
              });
            }
            // setTimeout(() => {
            //   let row = this.gridApi.getRowNode(res.id);
            //   row.data.justAdded = false;
            //   this.gridApi.applyTransaction({
            //     update: [row],
            //   });
            // }, 3000);
          }

          // let data = { ...res, bankGroupId: "loading" };
        }
      });
    }
  }

  openTransferBankDialog(data?) {
    this._dataService.showContextMenu = false;
    let dialogData = {
      cashboxId: this._bankService.selectedCashboxId(),
      data: data ? data : null,
    };
    const dialogRef = this.dialog.open(TransferBankDialogComponent, {
      width: "700px",
      maxHeight: "700px",
      data: dialogData,
      closeOnNavigation: true,
      disableClose: this._dataService.mobileDevice
        ? false
        : data
        ? false
        : true,
      panelClass: "materialDialog",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.agGridData$ = this._bankService.getBankTableData();
      }
    });
  }

  openSalaryDialog(data?) {
    this._dataService.showContextMenu = false;
    let dialogData = data
      ? data
      : {
          cashboxId: this._bankService.selectedCashboxId(),
        };
    const dialogRef = this.dialog.open(SalaryDialogComponent, {
      width: "800px",
      maxHeight: "700px",
      data: dialogData,
      panelClass: "materialDialog",
      closeOnNavigation: true,
      disableClose: this._dataService.mobileDevice
        ? false
        : data
        ? false
        : true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.agGridData$ = this._bankService.getBankTableData();
      }
    });
  }

  onCellMouseOver(params) {
    if (!this._dataService.showContextMenu)
      this.contextMenuData.data = params.data;
    this.hoverData = params.data;
  }

  onRightClick(event?) {
    event.preventDefault();
    if (this.contextMenuData.data != this.hoverData) {
      this.contextMenuData.data = this.hoverData;
    }
    const targetElement = event.target as HTMLElement;
    const rect = targetElement.getBoundingClientRect();

    this.contextMenuData.x = event.clientX;
    this.contextMenuData.y = rect.bottom + window.scrollY;

    this._dataService.showContextMenu = true;
  }

  contextMenu(data) {
    let lastOpened = this.contextMenuData.data
      ? this.contextMenuData.data.id
      : null;
    data.event.preventDefault();
    const targetElement = data.event.target as HTMLElement;
    const rect = targetElement.getBoundingClientRect();

    this.contextMenuData = {
      x: null,
      y: rect.bottom + window.scrollY,
      data: data.data,
    };
    this._dataService.showContextMenu =
      lastOpened == this.contextMenuData.data.id
        ? !this._dataService.showContextMenu
        : true;
  }
}

var filter = false;
var bankGroups = [];
