import {
  AfterViewInit,
  Component,
  NgZone,
  OnInit,
  ViewChild,
  effect,
  signal,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { DataService } from "./data.service";
import { AuthService } from "./auth.service";
import { fadeAnimation, flip, slideUpDown } from "./shared/fade.animation";
import { UntypedFormControl } from "@angular/forms";
import { interval } from "rxjs";
import { switchDatabase } from "./db.service";
import { SwUpdate } from "@angular/service-worker";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SyncService } from "./sync.service";
import { PusherService } from "./pusher.service";
import { ConfirmationDialogComponent } from "./shared/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { StockService } from "./stock/stock.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  animations: [fadeAnimation, slideUpDown, flip],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild("sidenav") sidenav: MatSidenav;
  btnToggleNav: HTMLElement = document.getElementsByClassName(
    "btnClose"
  )[0] as HTMLElement;
  sideNavMode: string;
  matOpened = localStorage.hasOwnProperty("sideNavOpenState")
    ? localStorage.getItem("sideNavOpenState")
    : true;
  showReports: boolean = true;
  showSideNav: boolean = false;
  windowScrolled: boolean = false;
  appVersion = environment.version;
  liActive: number = 0;
  liClicked: number;
  direction: boolean = false;
  iconState = this.matOpened ? "flipped" : "default";
  uuid = uuidv4();

  constructor(
    public _dataService: DataService,
    public _syncService: SyncService,
    public _authService: AuthService,
    private _stockService: StockService,
    private _pusher: PusherService,
    private swUpdate: SwUpdate,
    private ngZone: NgZone,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {
    window.addEventListener("focus", () => {
      this.checkForUpdate();
    });
    effect(
      () => {
        if (this._dataService.dateChangedSig()) {
          this._dataService.setActiveClassOnDates();
        }
        if (
          this._authService.userSignal() &&
          this._authService.userSignal()?.token &&
          navigator.onLine
        ) {
          this._syncService.syncDB();
          this._syncService.getSettings();
        }
        if (this._dataService.dbChanged()) {
          console.log("changed");
          this.changeDB(this._dataService.dbChanged());
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    this._dataService.uuid = this.uuid;
    // PUSHER
    var channel = this._pusher.init(this._dataService.dbTable);
    channel.bind("general", (data) => {
      if (data?.uuid !== this.uuid) {
        this._syncService.syncDB();
      }
    });
    // DEXIE DB
    if (!localStorage.hasOwnProperty("dbTable")) {
      localStorage.setItem(
        "dbTable",
        JSON.stringify(this._dataService.dbTable)
      );
      switchDatabase(this._dataService.dbTable);
    }
    // CHECK DEVICE TYPE
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      // MOBILE
      this.sideNavMode = "over";
      this.matOpened = false;
    } else {
      // PC
      this.sideNavMode = "side";
    }

    if (this.swUpdate.isEnabled) {
      this.checkForUpdate();
      this.ngZone.runOutsideAngular(() =>
        interval(1000 * 60 * 30).subscribe(() => {
          this.checkForUpdate();
        })
      );
    }

    this._dataService.setActiveClassOnDates();

    interval(1800000).subscribe(() => {
      this._syncService.syncDB();
    });
  }

  ngAfterViewInit(): void {
    if ("requestIdleCallback" in window) {
      window.requestIdleCallback(() => {
        // let materials = this._stockService.getMaterialTotals(
        //   new UntypedFormControl(moment()).value.format("YYYY-MM-DD")
        // );
        // materials.then((m: {}[]) => {
        //   m.forEach((mm: any) => {
        //     this._dataService
        //       .materials()
        //       .find((mat) => mat.id == mm.materialId).unitPrice = mm.unitPrice;
        //   });
        // });
        // console.log(this._dataService.formulaGroups());
        // this._dataService.formulaGroups().forEach(fg => {
        //   fg.formulas.for
        // });
      });
    }
  }

  toggleSidenav(event?, state?) {
    // ON PC STORE SIDENAV OPEN/CLOSE STATE
    if (state != null && !/Mobi|Android/i.test(navigator.userAgent)) {
      localStorage.setItem("sideNavOpenState", state);
      this._dataService.showSideNav = state;
    }
    // ON MOBILE CLOSE SIDENAV ON NAV ITEM CLICKS
    if (/Mobi|Android/i.test(navigator.userAgent) && state == null) {
      this.sidenav.close();
    }
  }

  setDateRange(
    startDate: moment.Moment,
    endDate: moment.Moment,
    clickedRange: any
  ) {
    this._dataService.dateRanges.forEach((range) => {
      range.active = range === clickedRange;
    });
    this._dataService.startDate = new UntypedFormControl(startDate);
    this._dataService.setLS("startDate", this._dataService.startDate);
    this._dataService.endDate = new UntypedFormControl(endDate);
    this._dataService.setLS("endDate", this._dataService.endDate);
    this._dataService.dateChanged.next(moment().startOf("month").toString());
    this._dataService.dateChangedSig.set(
      this._dataService.startDate.value.format("DD-MM-YYYY") +
        this._dataService.endDate.value.format("DD-MM-YYYY")
    );
  }

  reset() {
    const data = {
      header: "Sıfırla",
      body: "Verilənlər bazası sıfırlanıb yenidən sinxronizə ediləcək",
      footer: "Davam et?",
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: data,
      closeOnNavigation: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this._syncService.reset();
      }
    });
  }

  onScroll(event) {
    this.windowScrolled = event.target.scrollTop > 100 ? true : false;
  }

  scrollTop() {
    setTimeout(() => {
      document.querySelector(".mat-sidenav-content").scrollTo(0, 0);
    }, 250);
  }

  scrollTopSmooth() {
    document.querySelector(".mat-sidenav-content, table").scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  checkForUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then((res) => {
        if (res) {
          this.toastr
            .info(
              'Zəhmət olmasa proqramı yeniləyin <br/><br/> <a class="btn btn-block" click="reloadPage()">Yenilə</a>',
              "Yeni versiya mövcuddur!",
              {
                disableTimeOut: true,
                closeButton: true,
                enableHtml: true,
                toastClass: "ngx-toastr toast-info bg-toastr",
                tapToDismiss: false,
              }
            )
            .onTap.pipe()
            .subscribe(() => window.location.reload());
        }
      });
    }
  }

  // ANIMATE ROUTES
  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRouteData["tab"] : "";
  }

  async changeDB(database?: string) {
    this._syncService.showSyncLine = true;
    this._dataService.dbTable = database;
    localStorage.setItem("dbTable", JSON.stringify(database));
    switchDatabase(database);
    this._syncService.syncDate = this._dataService.getLS("syncDate");
    this._syncService.getLSData();
    if (navigator.onLine) {
      this._syncService.syncDB();
      this._syncService.getSettings();
    }
    this._dataService.dbName.set(
      this._dataService.database.find((db) => db.db === database)?.name
    );
    this._dataService.dbChanged.set(database);
  }

  // HAMMER SWIPE CONTROLS
  // swipeForMobile(event) {
  //   if (/Mobi|Android/i.test(navigator.userAgent) &&
  //     !event.target.className.includes("mat-header") &&
  //     !event.target.className.includes("mat-cell") &&
  //     !event.target.className.includes("mat-sort") &&
  //     !event.target.closest('.noHammerSwipe')
  //   ) {
  //     this.sidenav?.open();
  //   }
  // }
}
