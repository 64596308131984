<div class="container-fluid">
  <!-- (swiperight)="sidenav?.open()" (swipeleft)="sidenav?.close()" (panright)="swipeForMobile($event)" -->
  <div class="row">
    <p
      matTooltip="İnternet bağlantınız yoxdur"
      matTooltipClass="line-broken-tooltip"
      class="noConnection"
      [ngClass]="{ bottom60: windowScrolled }"
      *ngIf="!(_dataService.online$ | async)"
    >
      <mat-icon>wifi_off</mat-icon>
    </p>
    <button
      matTooltip="Səhifəni yuxarı sürüşdür"
      matTooltipClass="line-broken-tooltip"
      class="btnScrollTop"
      (click)="scrollTopSmooth()"
      *ngIf="windowScrolled"
    >
      <mat-icon>expand_less</mat-icon>
    </button>
    <main
      role="main"
      class="col-md-12 ml-sm-auto col-lg-12 main"
      (click)="_dataService.showContextMenu = false"
    >
      <!-- <app-nav></app-nav> -->

      <div>
        <mat-sidenav-container class="sidenav-container">
          <mat-sidenav
            #sidenav
            [(mode)]="sideNavMode"
            [opened]="matOpened"
            (closed)="toggleSidenav($event, false)"
            (opened)="toggleSidenav($event, true)"
            (closedStart)="
              iconState = iconState === 'default' ? 'flipped' : 'default'
            "
            (openedStart)="
              iconState = iconState === 'default' ? 'flipped' : 'default'
            "
            *ngIf="_authService.loggedInUserValue"
          >
            <div class="sidebar-sticky">
              <div class="navbar-brand">
                <a routerLink="./Home" class="reports logoJJ"> JJ </a>

                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    font-family: 'Avant';
                    font-size: 10px;
                    line-height: 11px;
                    justify-content: end;
                    margin-left: 3px;
                    padding-top: 25px;
                  "
                >
                  <span class="reports" style="padding-left: 5px"
                    >v{{ appVersion }}</span
                  >
                  <span class="reports">ACCG</span>
                </div>
              </div>

              <ul class="nav flex-column" style="margin-top: -2px">
                <li
                  class="nav-item"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  [matTooltip]="!sidenav.opened ? 'Ana Səhifə' : ''"
                  matTooltipClass="navToolTip"
                  matTooltipPosition="after"
                  *ngIf="_authService.loggedInUserValue.role == 1"
                  (click)="toggleSidenav($event)"
                  style="margin-top: -1px"
                >
                  <a
                    class="nav-link"
                    routerLink="./Home"
                    (click)="clickedIndex = 0"
                  >
                    <mat-icon>home</mat-icon>
                    <span class="nav-text"> Ana Səhifə</span>
                  </a>
                </li>
                <li
                  class="nav-item"
                  routerLinkActive="active"
                  [matTooltip]="!sidenav.opened ? 'Anbar' : ''"
                  matTooltipClass="navToolTip"
                  matTooltipPosition="after"
                  *ngIf="
                    _authService.loggedInUserValue.role == 1 ||
                    _authService.loggedInUserValue.role == 2
                  "
                  (click)="toggleSidenav($event); direction = !direction"
                >
                  <a
                    class="nav-link"
                    routerLink="./Stock"
                    (click)="clickedIndex = 1"
                  >
                    <mat-icon>inventory_2</mat-icon>
                    <span class="nav-text"> Anbar</span>
                  </a>
                </li>
                <li
                  class="nav-item"
                  routerLinkActive="active"
                  [matTooltip]="!sidenav.opened ? 'İstehsal' : ''"
                  matTooltipClass="navToolTip"
                  matTooltipPosition="after"
                  *ngIf="
                    _authService.loggedInUserValue.role == 1 ||
                    _authService.loggedInUserValue.role == 3
                  "
                  (click)="toggleSidenav($event)"
                >
                  <a
                    class="nav-link"
                    routerLink="./Products"
                    (click)="clickedIndex = 2"
                  >
                    <mat-icon>precision_manufacturing</mat-icon>
                    <span class="nav-text"> İstehsal</span>
                  </a>
                </li>
                <li
                  class="nav-item"
                  routerLinkActive="active"
                  [matTooltip]="!sidenav.opened ? 'Satışlar' : ''"
                  matTooltipClass="navToolTip"
                  matTooltipPosition="after"
                  *ngIf="_authService.loggedInUserValue.role == 1"
                  (click)="toggleSidenav($event)"
                >
                  <a
                    class="nav-link"
                    routerLink="./Sells"
                    (click)="clickedIndex = 3"
                  >
                    <mat-icon>shopping_cart_checkout</mat-icon>
                    <span class="nav-text"> Satışlar</span>
                  </a>
                </li>
                <li
                  class="nav-item"
                  routerLinkActive="active"
                  [matTooltip]="!sidenav.opened ? 'Kassa' : ''"
                  matTooltipClass="navToolTip"
                  matTooltipPosition="after"
                  *ngIf="_authService.loggedInUserValue.role == 1"
                  (click)="toggleSidenav($event)"
                >
                  <a
                    class="nav-link"
                    routerLink="./Bank"
                    (click)="clickedIndex = 4"
                  >
                    <mat-icon>account_balance_wallet</mat-icon>
                    <span class="nav-text"> Kassa</span>
                  </a>
                </li>
                <li
                  class="nav-item"
                  routerLinkActive="active"
                  [matTooltip]="!sidenav.opened ? 'Müştərilər' : ''"
                  matTooltipClass="navToolTip"
                  matTooltipPosition="after"
                  *ngIf="_authService.loggedInUserValue.role == 1"
                  (click)="toggleSidenav($event)"
                >
                  <a
                    class="nav-link"
                    routerLink="./Customers"
                    (click)="clickedIndex = 5"
                  >
                    <mat-icon>people</mat-icon>
                    <span class="nav-text"> Müştərilər</span>
                  </a>
                </li>
                <li
                  class="nav-item hideNavText"
                  routerLinkActive="active"
                  [matTooltip]="!sidenav.opened ? 'Mənfəət və Zərər' : ''"
                  matTooltipClass="navToolTip"
                  matTooltipPosition="after"
                  *ngIf="_authService.loggedInUserValue.role == 1"
                  (click)="showReports = !showReports"
                  style="cursor: pointer"
                >
                  <a class="nav-link">
                    <mat-icon>summarize</mat-icon>
                    <span class="nav-text hideNavText"> Hesabatlar </span>
                    <mat-icon class="hideNavText" style="margin-left: 8px"
                      >expand_more</mat-icon
                    >
                  </a>
                </li>
                <div *ngIf="showReports" class="hideNavText">
                  <li
                    class="nav-item"
                    routerLinkActive="active"
                    [matTooltip]="!sidenav.opened ? 'Mənfəət və Zərər' : ''"
                    matTooltipClass="navToolTip"
                    matTooltipPosition="after"
                    *ngIf="_authService.loggedInUserValue.role == 1"
                    (click)="toggleSidenav($event)"
                  >
                    <a
                      class="nav-link"
                      routerLink="./Profit"
                      (click)="clickedIndex = 7"
                      style="
                        font-size: 10px;
                        line-height: 1;
                        padding-block: 9px 7px;
                      "
                    >
                      <span
                        class="nav-text"
                        style="
                          font-size: 14px;
                          font-family: 'Mulish';
                          padding-left: 17px;
                        "
                      >
                        - Mənfəət və Zərər</span
                      >
                    </a>
                  </li>
                  <li
                    class="nav-item"
                    routerLinkActive="active"
                    [matTooltip]="!sidenav.opened ? 'Mənfəət və Zərər' : ''"
                    matTooltipClass="navToolTip"
                    matTooltipPosition="after"
                    *ngIf="_authService.loggedInUserValue.role == 1"
                    (click)="toggleSidenav($event)"
                  >
                    <a
                      class="nav-link"
                      routerLink="./CashDist"
                      (click)="clickedIndex = 7"
                      style="
                        font-size: 10px;
                        line-height: 1;
                        padding-block: 9px 7px;
                      "
                    >
                      <span
                        class="nav-text"
                        style="
                          font-size: 14px;
                          font-family: 'Mulish';
                          padding-left: 17px;
                        "
                      >
                        - Kassa Bölgüsü</span
                      >
                    </a>
                  </li>
                </div>
                <li
                  class="nav-item"
                  routerLinkActive="active"
                  [matTooltip]="!sidenav.opened ? 'Dev' : ''"
                  matTooltipClass="navToolTip"
                  matTooltipPosition="after"
                  *ngIf="
                    _authService.loggedInUserValue.email_address ==
                    'cavidnebi@gmail.com'
                  "
                  (click)="toggleSidenav($event)"
                >
                  <a
                    class="nav-link"
                    routerLink="./Dev"
                    (click)="clickedIndex = 6"
                  >
                    <mat-icon>tune</mat-icon>
                    <span class="nav-text"> Dev</span>
                  </a>
                </li>
              </ul>
              <h6
                class="sidebar-heading d-flex justify-content-between align-items-center px-3 text-muted reports"
                style="margin-top: auto"
              >
                <span>Tarix Aralığı</span>
                <a
                  class="d-flex align-items-center text-muted"
                  style="text-decoration: none"
                >
                  <i class="material-icons" style="font-size: 17px"
                    >control_point</i
                  >
                </a>
              </h6>
              <ul class="nav flex-column reports" style="margin-bottom: 123px">
                <li
                  class="nav-item"
                  *ngFor="let range of _dataService.dateRanges"
                  [ngClass]="{ activeDate: range.active }"
                >
                  <a
                    class="nav-link"
                    (click)="
                      setDateRange(range.start, range.end);
                      toggleSidenav($event);
                      range.active = true
                    "
                    style="cursor: pointer"
                  >
                    <span
                      class="material-symbols-outlined"
                      style="font-size: 10px"
                    >
                      date_range
                    </span>
                    <span class="nav-text-small">{{ range.label }}</span>
                  </a>
                </li>
              </ul>

              <!-- DATABASE SELECTOR -->
              <div
                class="userSelector"
                style="
                  margin-top: auto;
                  border-top: 1px solid #405c79;
                  background-color: #3c536a;
                  position: absolute;
                  bottom: 80px;
                  width: 100%;
                "
              >
                <div
                  *ngIf="_authService.loggedInUserValue.fullname"
                  class="nav-link nav-item clipText"
                  style="
                    border-bottom: 1px solid #ffffff21;
                    cursor: pointer;
                    white-space: nowrap;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 40px;
                  "
                  (click)="dbSettings.state = !dbSettings.state"
                  [matTooltip]="
                    !sidenav.opened
                      ? _authService.loggedInUserValue.fullname
                      : ''
                  "
                  matTooltipClass="navToolTip logoutTooltip"
                  matTooltipPosition="after"
                >
                  <i class="material-symbols-outlined"> hard_drive </i>
                  <span
                    class="nav-text mx-auto"
                    style="height: 20px; line-height: 20px"
                    >{{ _dataService.dbName() }}</span
                  >
                </div>
                <!-- SETTINGS MENU -->
                <div
                  class="userSettings"
                  #dbSettings
                  [@slideUpDown]="dbSettings.state"
                >
                  <div
                    style="
                      display: flex;
                      padding: 0.4rem 0;
                      font-size: 12px;
                      background-color: #0000003b;
                      justify-content: center;
                    "
                  >
                    <span class="hideNavText">Baza</span>
                  </div>
                  @for(db of _dataService.database; track db.id) { @if(db.name
                  !== _dataService.dbName()) {
                  <a
                    class="nav-link nav-item d-flex justify-content-center align-items-center"
                    (click)="
                      changeDB(db.db); dbSettings.state = !dbSettings.state
                    "
                    [matTooltip]="!sidenav.opened ? 'Sinxronlaşdır' : ''"
                    matTooltipClass="navToolTip"
                  >
                    <span class="material-symbols-outlined"> sync </span>
                    <span
                      class="hideNavText mx-auto"
                      style="vertical-align: top; font-size: 15px"
                      >{{ db.name }}</span
                    ></a
                  >
                  } }
                </div>
              </div>
              <!-- USER SETTINGS -->
              <div
                class="userSelector"
                style="
                  margin-top: auto;
                  border-top: 1px solid #405c79;
                  background-color: #3c536a;
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                "
              >
                <div
                  *ngIf="_authService.loggedInUserValue.fullname"
                  class="nav-link nav-item clipText"
                  style="
                    border-bottom: 1px solid #ffffff21;
                    cursor: pointer;
                    white-space: nowrap;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 40px;
                  "
                  (click)="userSettings.state = !userSettings.state"
                  [matTooltip]="
                    !sidenav.opened
                      ? _authService.loggedInUserValue.fullname
                      : ''
                  "
                  matTooltipClass="navToolTip logoutTooltip"
                  matTooltipPosition="after"
                >
                  <mat-icon
                    style="
                      margin-left: -3px;
                      margin-right: 5px;
                      line-height: 24px;
                    "
                    >person</mat-icon
                  >
                  <span
                    class="nav-text mx-auto"
                    style="height: 20px; line-height: 20px"
                    >{{ _authService.loggedInUserValue.fullname }}</span
                  >
                </div>
                <!-- SETTINGS MENU -->
                <div
                  class="userSettings"
                  #userSettings
                  [@slideUpDown]="userSettings.state"
                >
                  <div
                    style="
                      display: flex;
                      padding: 0.4rem 0;
                      font-size: 12px;
                      background-color: #0000003b;
                      justify-content: center;
                    "
                  >
                    <span class="hideNavText">Son Yeniləmə: </span>
                    <span
                      [matTooltip]="
                        !sidenav.opened
                          ? (_syncService.syncDate | slice : 6) +
                            ' Son Yeniləmə '
                          : ''
                      "
                      class="syncDate"
                      >{{
                        sidenav.opened
                          ? _syncService.syncDate
                          : (_syncService.syncDate | slice : 0 : 5)
                      }}</span
                    >
                  </div>
                  <a
                    class="nav-link nav-item d-flex justify-content-center align-items-center"
                    (click)="
                      _syncService.showSyncLine = true;
                      _syncService.syncDB();
                      userSettings.state = !userSettings.state;
                      toggleSidenav($event)
                    "
                    [matTooltip]="!sidenav.opened ? 'Sinxronlaşdır' : ''"
                    matTooltipClass="navToolTip"
                  >
                    <span class="material-symbols-outlined"> sync </span>
                    <span
                      class="hideNavText mx-auto"
                      style="vertical-align: top; font-size: 15px"
                      >Sinxronlaşdır</span
                    ></a
                  >
                  <a
                    class="nav-link nav-item d-flex justify-content-center align-items-center"
                    (click)="
                      reset();
                      userSettings.state = !userSettings.state;
                      toggleSidenav($event)
                    "
                    [matTooltip]="!sidenav.opened ? 'Sıfırla' : ''"
                    matTooltipClass="navToolTip"
                  >
                    <span class="material-symbols-outlined"> cloud_sync </span>
                    <span
                      class="hideNavText mx-auto"
                      style="vertical-align: top; font-size: 15px"
                      >Sıfırla</span
                    ></a
                  >
                </div>
                <a
                  class="nav-link nav-item"
                  routerLink="./Login"
                  (click)="toggleSidenav($event); _authService.logoutUser()"
                  [matTooltip]="!sidenav.opened ? 'Çıxış' : ''"
                  matTooltipClass="navToolTip logoutTooltip"
                  matTooltipPosition="after"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 40px;
                  "
                >
                  <mat-icon>logout</mat-icon>
                  <span class="nav-text mx-auto"> Çıxış</span>
                </a>
              </div>
            </div>
            <div
              #btnClose
              class="btnCloseContainer"
              (click)="
                sidenav.toggle();
                _dataService.showSideNav = !_dataService.showSideNav
              "
            >
              <span class="material-symbols-outlined" [@flip]="iconState">
                menu_open
              </span>
            </div>
          </mat-sidenav>

          <mat-sidenav-content
            #sidenavContent
            (scroll)="onScroll($event)"
            class="sidenav-content"
            [ngClass]="{ loginPage: !_authService.loggedInUserValue }"
          >
            <div
              #btnClose
              class="btnCloseContainer nav-item mobileOnly"
              (click)="sidenav.toggle()"
              [ngClass]="{ 'd-none': !_authService.loggedInUserValue }"
            >
              <div class="esasLine">
                <div class="line"></div>
              </div>
            </div>
            <main [@fadeAnimation]="getRouterOutletState(o)">
              <router-outlet
                #o="outlet"
                (deactivate)="scrollTop()"
              ></router-outlet>
            </main>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </div>
    </main>
  </div>
</div>
