import { Component, Inject, OnInit, Optional } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { IApiResult } from "src/app/shared/models/apiresult.interface";
import { forkJoin, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { db } from "src/app/db.service";
import { DataService } from "src/app/data.service";
import "lodash";
import { NewMaterialDialogComponent } from "../material-dialog/material-dialog.component";
import { NewProductDialogComponent } from "../product-dialog/product-dialog.component";
declare var _: any;

// string to number for formula id
// txt.match(/\d/g)

@Component({
  selector: "app-new-formula-dialog",
  templateUrl: "./formula-dialog.component.html",
  styleUrls: ["./formula-dialog.component.css"],
})
export class NewFormulaDialogComponent implements OnInit {
  formulaGroups = [];
  formulas: {
    id: string;
    productId?: number;
    includedFormulaGroupId?: number;
    quantity: number;
    price: number;
    currency: string;
    sum: number;
    type?: string;
    unit?: string;
    entryId?: number;
    name?: string;
  }[] = [];
  materialSums;
  dataLoading: boolean = false;
  formulaName: string;
  updatedMaterialPrices: { id?: number; price: number }[] = [];
  removedMaterials: {
    id: number;
    materialId?: number;
    productId?: number;
    includedFormulaGroupId?: number;
  }[] = [];
  removedMaterialFormulaIds: number[] = [];
  postedFormulaGroupId;
  formulaCost: number;
  formulaWeight: number = 0;
  selectedFormulaGroupId: number;
  selectData: {}[] = [];

  constructor(
    public dialogRef: MatDialogRef<NewFormulaDialogComponent>,
    private http: HttpClient,
    private toastr: ToastrService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: IApiResult,
    public _dataService: DataService,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.formulaGroups =
      this._dataService.formulaGroups() ?? (await db.formulaGroups.toArray());
    // TODO THIS ONE ONLY LOADS AFTER OPENING STOCK PAGE
    this.materialSums = JSON.parse(localStorage.getItem("groupSumData")) || [];

    this.materialSums.forEach((material) => {
      if (_.find(this._dataService.materials(), { name: material.name })) {
        _.find(this._dataService.materials(), {
          name: material.name,
        }).totalQuantity = material.totalQuantity;
      }
    });

    this._dataService.materials().forEach((material) => {
      this.selectData.push({
        ...material,
        type: "Material",
        id: material.id + "m",
      });
    });

    this._dataService.products().forEach((product) => {
      this.selectData.push({
        ...product,
        type: "Məhsul",
        id: product.id + "p",
      });
    });

    this.formulaGroups.forEach((formula) => {
      if (formula.id != this.dialogData.formulaId) {
        this.selectData.push({
          ...formula,
          type: "Formul",
          id: formula.id + "f",
        });
      }
    });

    if (this.dialogData.formulaId) {
      let selectedFormulaGroup =
        this.formulaGroups.find((f) => f.id == this.dialogData.formulaId) ??
        (await db.formulaGroups.get(this.dialogData.formulaId));
      this.formulaName = selectedFormulaGroup?.name ?? "";
      this.dataLoading = true;

      console.log(selectedFormulaGroup);

      selectedFormulaGroup.formulas.sort((a, b) => b.quantity - a.quantity);

      selectedFormulaGroup.formulas.forEach((e) => {
        let material = null;
        console.log("materials: ",selectedFormulaGroup.materials);
        console.log("e.meterialId: ",e.materialId);
        if (e.materialId != null)
          material =
            selectedFormulaGroup.materials.find((f) => f.id == e.materialId) ??
            null;

        let type =
          e.materialId != null
            ? "material"
            : e.productId != null
            ? "məhsul"
            : "formul";
        this.formulas.push({
          entryId: e.id,
          id: e.materialId
            ? e.materialId + "m"
            : e.productId
            ? e.productId + "p"
            : e.includedFormulaGroupId + "f",
          productId: e.productId,
          type: type,
          includedFormulaGroupId: e.includedFormulaGroupId,
          quantity: e.quantity,
          price: material?.price ?? 1,
          unit: material?.unit ?? null,
          currency: "₼",
          sum:
            parseFloat((e.quantity * material?.price || 1).toFixed(3)) ?? null,
        });
      });

      this.calculateTotalPrice();
      this.dataLoading = false;
    } else {
      this.formulas = [
        {
          id: null,
          quantity: null,
          price: null,
          unit: null,
          currency: "₼",
          sum: null,
        },
      ];
    }
  }

  addFormula(data, event) {
    event.target.innerText = "Gözləyin ...";
    event.target.disabled = true;
    this.dataLoading = true;
    let formulas: {}[] = [];
    let formulaUpdates: {}[] = [];
    let postFormulaGroup,
      postMaterialPriceUpdates: Observable<Object> = of(null),
      postMaterialRemoves: Observable<Object> = of(null),
      postFormulas,
      postFormulaUpdates: Observable<Object> = of(null);
    let updatedMaterialPriceIds: string = "";
    let removedMaterialIds: string = "";

    // CHECK MATERIAL PRICE UPDATES
    this.formulas.forEach((e) => {
      if (e.id.slice(-1) == "m") {
        if (
          e.price !=
          _.find(this._dataService.materials(), { id: e.id.replace(/\D/g, "") })
            ?.price
        ) {
          this.updatedMaterialPrices.push({ price: e.price });
          updatedMaterialPriceIds += e.id.replace(/\D/g, "") + ",";
        }
      }
    });

    if (updatedMaterialPriceIds.length) {
      updatedMaterialPriceIds = updatedMaterialPriceIds.substring(
        0,
        updatedMaterialPriceIds.length - 1
      );
      postMaterialPriceUpdates = this.http.put(
        this._dataService.dbBase +
          "api.php/records/materials/" +
          updatedMaterialPriceIds,
        this.updatedMaterialPrices
      );
    }

    // CHECK REMOVED MATERIALS FROM FORMULA
    if (this.removedMaterialFormulaIds.length > 0) {
      postMaterialRemoves = this.http.delete(
        this._dataService.dbBase +
          "api.php/records/formulas/" +
          this.removedMaterialFormulaIds
      );
    }

    let formulaGroupData = {
      name: data.name,
      cost: this.formulaCost,
      type: null,
    };
    if (this.dialogData.uzleme) {
      formulaGroupData.type = 1;
    }

    // FORMULA GROUPS
    if (this.dialogData.formulaId) {
      postFormulaGroup = this.http.put(
        this._dataService.dbBase +
          "api.php/records/formulaGroups/" +
          this.dialogData.formulaId,
        { name: data.name, cost: this.formulaCost }
      );
    } else {
      postFormulaGroup = this.http.post(
        this._dataService.dbBase + "api.php/records/formulaGroups",
        formulaGroupData
      );
    }

    postFormulaGroup
      .pipe(
        map((id) => {
          this.postedFormulaGroupId = id;
          let formulaIds: string = "";

          this.formulas.forEach((f, index) => {
            let entryType = f.id.slice(-1);
            // existing formula updates
            if (f.entryId) {
              if (entryType == "m") {
                formulaUpdates.push({
                  quantity: f.quantity,
                  materialId: f.id.replace(/\D/g, ""),
                });
              } else if (entryType == "p") {
                formulaUpdates.push({
                  quantity: f.quantity,
                  productId: f.id.replace(/\D/g, ""),
                });
              } else {
                formulaUpdates.push({
                  quantity: f.quantity,
                  includedFormulaGroupId: f.id.replace(/\D/g, ""),
                });
              }
              formulaIds += f.entryId + ",";
            }
            // new additions
            if (!f.entryId) {
              if (entryType == "m") {
                formulas.push({
                  formulaGroupId: this.dialogData.formulaId
                    ? this.dialogData.formulaId
                    : null,
                  quantity: f.quantity,
                  materialId: f.id.replace(/\D/g, ""),
                });
              } else if (entryType == "p") {
                formulas.push({
                  formulaGroupId: this.dialogData.formulaId
                    ? this.dialogData.formulaId
                    : null,
                  quantity: f.quantity,
                  productId: f.id.replace(/\D/g, ""),
                });
              } else {
                formulas.push({
                  formulaGroupId: this.dialogData.formulaId
                    ? this.dialogData.formulaId
                    : null,
                  quantity: f.quantity,
                  includedFormulaGroupId: f.id.replace(/\D/g, ""),
                });
              }
            }
          });
          if (formulaIds.length) {
            formulaIds = formulaIds.substring(0, formulaIds.length - 1);
          }
          if (this.dialogData.formulaId) {
            postFormulaUpdates = this.http.put(
              this._dataService.dbBase +
                "api.php/records/formulas/" +
                formulaIds,
              formulaUpdates
            );
          }
          postFormulas = this.http.post(
            this._dataService.dbBase + "api.php/records/formulas/",
            formulas
          );
        }),
        switchMap(() =>
          forkJoin({
            postFormulas,
            postFormulaUpdates,
            postMaterialPriceUpdates,
            postMaterialRemoves,
          })
        )
      )
      .subscribe((res) => {
        this.dialogRef.close({
          id: this.postedFormulaGroupId,
          name: data.name,
        });
        this.toastr.success("Əlavə edildi!");
      });
  }

  getMaterialDetails(materialId, formulaId, type) {
    if (type.id && (type.id.slice(-1) == "p" || type.id.slice(-1) == "f")) {
      this.formulas[formulaId].quantity = 1;
      this.formulas[formulaId].price = 1;
    }

    if (type.id) {
      const typeLastChar = type.id.slice(-1);
      this.formulas[formulaId].type =
        typeLastChar == "p"
          ? "məhsul"
          : typeLastChar == "m"
          ? "material"
          : "formul";
    }

    if (materialId && type.id && type.id.slice(-1) == "m") {
      const material = this._dataService
        .materials()
        .find((x) => x.id == materialId.replace(/\D/g, ""));
      this.formulas[formulaId].price = material.price;
      this.formulas[formulaId].unit = material.unit;
    } else {
      this.formulas[formulaId].price = null;
      this.formulas[formulaId].unit = "əd";
    }
  }

  nextFormula() {
    this.formulas.push({
      id: null,
      quantity: null,
      price: null,
      currency: "₼",
      sum: null,
    });
  }

  removeFormula(index, formula) {
    if (formula?.entryId) {
      this.removedMaterialFormulaIds.push(formula.entryId);
    }
    this.formulas.splice(index, 1);
  }

  // TODO this needs fixing
  newFormulaDialog(formula?) {
    let dialogRef;
    let dialogType;
    if (formula.type) {
      dialogType =
        formula.type == "formul"
          ? NewFormulaDialogComponent
          : formula.type == "material"
          ? NewMaterialDialogComponent
          : NewProductDialogComponent;

      let data =
        dialogType == NewProductDialogComponent
          ? this._dataService
              .products()
              .find((x) => x.id == formula.id.replace(/\D/g, ""))
          : dialogType == NewMaterialDialogComponent
          ? this._dataService
              .materials()
              .find((x) => x.id == formula.id.replace(/\D/g, ""))
          : { formulaId: parseInt(formula.id.replace(/\D/g, "")) };
      dialogRef = this.dialog.open(dialogType, {
        width: "800px",
        data: data,
        closeOnNavigation: true,
        panelClass: "materialDialog",
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          // this._dataService.getFormulaGroups().subscribe((r) => {
          //   this._dataService.formulaGroups() = r;
          //   localStorage.setItem("formulaGroups", JSON.stringify(r));
          // });
          // this.formulaGroups = [...this.formulaGroups, res];
          // this.data.formulaGroupId = res.id;
        }
      });
    }
  }

  async getFormulaGroup(id: number) {
    let result = await db.formulaGroups.get(id);
    return result;
  }

  calculateTotalPrice(index?) {
    console.log("formulas: ", this.formulas);
    let totalPrice = 0;
    this.formulaWeight = 0;

    // CHECK CURRENCY TYPE
    if (index || index == 0) {
      if (this.formulas[index]?.currency == "₼") {
        this.formulas[index].sum = parseFloat(
          (this.formulas[index].quantity * this.formulas[index].price).toFixed(
            3
          )
        );
      } else {
        this.formulas[index].sum = parseFloat(
          (
            this.formulas[index].quantity *
            this.formulas[index].price *
            1.7
          ).toFixed(3)
        );
      }
    }

    this.formulas.forEach((el) => {
      if (el.currency == "₼") {
        totalPrice += el.quantity * el.price;
        totalPrice = parseFloat(totalPrice.toFixed(3));
      } else {
        totalPrice += el.quantity * el.price * 1.7;
        totalPrice = parseFloat(totalPrice.toFixed(3));
      }
      if (el.type == "material" && el.unit == "kg") {
        this.formulaWeight += el.quantity;
      }
      this.formulaCost = parseFloat(
        (totalPrice / this.formulaWeight).toFixed(3)
      );
    });
  }

  manatConverter(index) {
    if (this.formulas[index].currency == "₼") {
      this.formulas[index].currency = "$";
    } else {
      this.formulas[index].currency = "₼";
    }
  }
}
