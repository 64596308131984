import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
  signal,
  effect,
} from "@angular/core";
import { combineLatest, Subject } from "rxjs";
import { DataService } from "../data.service";
import { HttpClient } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import "lodash";
import { ToastrService } from "ngx-toastr";
declare var _: any;
import { db } from "src/app/db.service";
import { MatTableDataSource } from "@angular/material/table";
import { ChartConfiguration, ChartData } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { BankService } from "../bank/bank.service";
import { SellsService } from "../sells/sells.service";
import { StockService } from "../stock/stock.service";
import { valueChange } from "../shared/fade.animation";
import { SyncService } from "../sync.service";
import { PusherService } from "../pusher.service";
import { CustomersService } from "../customers/customers.service";
import { UntypedFormControl } from "@angular/forms";
import moment from "moment";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  animations: [valueChange],
})
export class DashboardComponent implements OnInit, OnDestroy {
  _dataService = inject(DataService);
  _authService = inject(AuthService);
  _bankService = inject(BankService);
  _sellsService = inject(SellsService);
  _stockService = inject(StockService);
  _syncService = inject(SyncService);
  _customersService = inject(CustomersService);
  _pusher = inject(PusherService);
  _router = inject(Router);
  ngZone = inject(NgZone);
  toastr = inject(ToastrService);
  http = inject(HttpClient);
  dialog = inject(MatDialog);

  constructor() {
    effect(
      () => {
        if (this._bankService.selectedCashboxId()) {
          this.selectedCashboxName = signal(
            this._dataService
              .cashBox()
              .find((c) => c.id == this._bankService.selectedCashboxId())
              ?.name || "Kassa"
          );
          this._bankService._selectedCashboxId$.next(
            this._bankService.selectedCashboxId()
          );
        }
        if (this._stockService.selectedStockGroupId()) {
          this.selectedStockGroupName = signal(
            this._dataService
              .stockGroups()
              .find((c) => c.id == this._stockService.selectedStockGroupId())
              ?.name || "Anbar"
          );
          this._stockService._selectedStockGroupId$.next(
            this._stockService.selectedStockGroupId()
          );
        }
        if (this._dataService.dbChanged()) {
          this.changeDBNavButtons();
          // this._syncService.newDataSynced.next(
          //   moment(new Date()).format("HH:mm DD/MM/YY")
          // );
        }
      },
      { allowSignalWrites: true }
    );
  }
  istehsal: {}[] = [];
  sellsGrouppedByProducts: {}[];
  dataStream;
  dataLoading;
  lastTransactions = this._dataService.getLS("lastTransactions") || [];
  bankTotals = this._dataService.getLS("bankTotals");
  sellsTotals = this._dataService.getLS("sellsTotals") || [];
  stockTotals = this._dataService.getLS("stockTotals") || [];
  selectedCashboxName = this._bankService.selectedCashboxId()
    ? signal(
        this._dataService
          .cashBox()
          .find((c) => c.id == this._bankService.selectedCashboxId())?.name
      )
    : signal("Kassa");
  selectedStockGroupName = signal("Anbar");
  debitKredit;
  profit: { sellsTotal; costTotal; administrativXercler } =
    this._dataService.getLS("profit") ?? {
      sellsTotal: 0,
      costTotal: 0,
      administrativXercler: 0,
    };
  totalProduction = this._dataService.getLS("totalProduction") ?? 0;
  outgoingsByCategory = this._dataService.getLS("outgoingsByCategory") || [];
  selectedDatabase: string = this._dataService.database[0].db;
  @ViewChild("card") card: ElementRef;
  @ViewChild("cardsContainer") cardsContainer: ElementRef;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  componentDestroyed$: Subject<boolean> = new Subject();
  public accountsOverall: any;
  accountsOverallTotals: { sells; income; buy; outgoings } =
    this._dataService.getLS("accountsOverallTotals") || {
      sells: 0,
      income: 0,
      buy: 0,
      outgoings: 0,
    };
  displayedColumns: string[] = [
    "date",
    "sells",
    "income",
    "sellsResidue",
    "stock",
    "outgoings",
    "stockResidue",
  ];

  columnDefs = [
    { headerName: "", field: "tType", flex: 1, maxWidth: 80 },
    {
      headerName: "",
      field: "customerName",
      flex: 2,
      cellRenderer: (params) => {
        return params.data.refersTo
          ? `${params.value}<span>R</span>`
          : params.value;
      },
      cellClass: (params) => {
        return params.data.refersTo ? "editedTransaction" : "";
      },
    },
    {
      headerName: "",
      field: "sum",
      flex: 1,
      cellRenderer: (params) => {
        let result;
        const value = Number(params.value); // Convert to number

        result = !isNaN(value)
          ? `${value
              .toFixed(0)
              .toString()
              .replace(
                /\B(?=(\d{3})+(?!\d))/g,
                " "
              )}<span class="manat" style="font-size: 12px!important; margin-left: 3px">₼</span>`
          : "*";
        return result;
      },
    },
    {
      headerName: "",
      field: "date",
      flex: 1,
      minWidth: 90,
      cellRenderer: (params) => {
        return params.value.split("-").reverse().join("-");
      },
    },
  ];

  jwtToken = signal(this._dataService.getLS("kapitalToken") || "");

  public lineChartOptions: ChartConfiguration["options"] = {
    responsive: true,
    backgroundColor: "#2665a4",
    scales: {
      x: {},
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    animation: {
      duration: 0,
    },
  };

  public pieChartOptions: ChartConfiguration["options"] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "left",
      },
    },
    animation: {
      duration: 0,
    },
  };

  public lineChartData: ChartData<"bar"> = this._dataService.getLS(
    "sellsChartData"
  ) || {
    labels: [],
    datasets: [{ data: [] }],
  };

  public pieChartData: ChartData = this._dataService.getLS("pieChartData") || {
    labels: [],
    datasets: [{ data: [], label: "" }],
  };

  navButtons = [];

  async ngOnInit() {
    this.changeDBNavButtons();
    this.accountsOverall = localStorage.hasOwnProperty("accountsOverall")
      ? new MatTableDataSource(
          JSON.parse(localStorage.getItem("accountsOverall"))
        )
      : new MatTableDataSource();
    this.sellsGrouppedByProducts = JSON.parse(
      localStorage.getItem("sellsGrouppedByProducts")
    );

    combineLatest([
      this._dataService.dateChanged,
      this._syncService.newDataSynced,
    ])
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.getData();
      });

    this._syncService.newDataSynced
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.setOveralls();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  async getData() {
    this.dataLoading = true;

    // console.time("getBankTotals");
    this.bankTotals = this._bankService.getTotals(
      this._dataService.startDate.value.format("YYYY-MM-DD"),
      this._dataService.endDate.value.format("YYYY-MM-DD")
    );
    // console.timeEnd("getBankTotals");

    this._dataService.setLS("bankTotals", this.bankTotals);

    // console.time("getSellsTotals");
    this.sellsTotals = this._sellsService.getTotals(
      this._dataService.startDate.value.format("YYYY-MM-DD"),
      this._dataService.endDate.value.format("YYYY-MM-DD")
    );
    // console.timeEnd("getSellsTotals");

    this._dataService.setLS("sellsTotals", this.sellsTotals);

    // console.time("getStockTotals");
    this.stockTotals = this._stockService.getTotals(
      this._dataService.startDate.value.format("YYYY-MM-DD"),
      this._dataService.endDate.value.format("YYYY-MM-DD")
    );
    // console.timeEnd("getStockTotals");

    this._dataService.setLS("stockTotals", this.stockTotals);

    // console.time("getOutgoingsByCategory");
    this.outgoingsByCategory = await this._bankService.getOutgoingsByCategory(
      this._dataService.startDate.value.format("YYYY-MM-DD"),
      this._dataService.endDate.value.format("YYYY-MM-DD")
    );
    const totalOut = this.outgoingsByCategory.reduce(
      (sum, obj) => (obj.bankGroupId != 30 ? sum + obj.out : sum),
      0
    );
    this.outgoingsByCategory = this.outgoingsByCategory.map((obj) => ({
      ...obj,
      percentage: (obj.out / totalOut) * 100,
    }));
    // console.timeEnd("getOutgoingsByCategory");

    this._dataService.setLS("outgoingsByCategory", this.outgoingsByCategory);

    this.totalProduction = await this.getTotalProduction();
    this._dataService.setLS("totalProduction", this.totalProduction);

    /* PIE CHART */
    let pieLabels = [];
    let pieChartData = [];
    let i = 0;
    for await (const element of this.outgoingsByCategory) {
      // KASSALAR ARASI KOCURMELER XARIC
      if (element.out > 0 && i < 10 && element.bankGroupId != 30) {
        i++;
        pieLabels.push(
          element.name + " - " + (element.percentage.toFixed(1) ?? 0) + "%"
        );
        pieChartData.push(element.out);
      }
    }
    const clone = this._dataService.getLS("pieChartData") || {
      labels: [],
      datasets: [{ data: [], label: "" }],
    };
    clone.labels = pieLabels;
    clone.datasets[0].data = pieChartData;

    if (JSON.stringify(clone) !== JSON.stringify(this.pieChartData)) {
      this.pieChartData = clone;
      this._dataService.setLS("pieChartData", this.pieChartData);
    }
    /* END PIE CHART */
    // console.time("Cust");
    let cust = await this._customersService.getCustomersBalance();
    cust.subscribe();
    // console.timeEnd("Cust");

    this.getLastTransactions();

    this.profit = await this._bankService.calculateProfit();
    this._dataService.setLS("profit", this.profit);

    this.dataLoading = false;

    this.pieChartOptions.animation = {
      duration: 500,
    };
  }

  async getOveralls() {
    const stocksDX = await db.stock.toArray();
    const sellsDX = await db.sells.toArray();
    const bankDX = await db.bank.toArray();

    const currentDate = new Date();
    const twelveMonthsAgo = new Date();
    twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 11);

    const stocks = stocksDX.reduce((accumulator, stock) => {
      const date = new Date(stock.date);
      const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;

      if (date >= twelveMonthsAgo && date <= currentDate) {
        if (!accumulator[monthKey]) {
          accumulator[monthKey] = 0;
        }

        if (stock.quantity > 0) {
          accumulator[monthKey] += stock.quantity * stock.price;
        }
      }

      return accumulator;
    }, {});

    const sells = sellsDX.reduce((accumulator, stock) => {
      const date = new Date(stock.date);
      const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;

      if (date >= twelveMonthsAgo && date <= currentDate) {
        if (!accumulator[monthKey]) {
          accumulator[monthKey] = 0;
        }

        accumulator[monthKey] += stock.quantity * stock.price;
      }

      return accumulator;
    }, {});

    const bankIn = bankDX.reduce((accumulator, bank) => {
      const date = new Date(bank.date);
      const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;

      if (date >= twelveMonthsAgo && date <= currentDate) {
        if (!accumulator[monthKey]) {
          accumulator[monthKey] = 0;
        }

        accumulator[monthKey] += bank.bankGroupId == 0 ? bank.income : 0;
      }

      return accumulator;
    }, {});

    const bankOut = bankDX.reduce((accumulator, bank) => {
      const date = new Date(bank.date);
      const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;

      if (date >= twelveMonthsAgo && date <= currentDate) {
        if (!accumulator[monthKey]) {
          accumulator[monthKey] = 0;
        }

        accumulator[monthKey] += bank.bankGroupId == 1 ? bank.outgoings : 0;
      }

      return accumulator;
    }, {});

    const stockOut = bankDX.reduce((accumulator, bank) => {
      const date = new Date(bank.date);
      const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;

      if (date >= twelveMonthsAgo && date <= currentDate) {
        if (!accumulator[monthKey]) {
          accumulator[monthKey] = 0;
        }

        accumulator[monthKey] += bank.bankGroupId == 1 ? bank.outgoings : 0;
      }

      return accumulator;
    }, {});

    let combinedObject = Object.assign(
      {},
      stocks,
      sells,
      bankIn,
      bankOut,
      stockOut
    );

    const result = Object.keys(combinedObject)
      .map((monthKey) => {
        const [year, month] = monthKey.split("-");

        return {
          yr: parseInt(year),
          mon: parseInt(month),
          sells: sells[monthKey] || 0,
          income: bankIn[monthKey],
          outgoings: bankOut[monthKey],
          stock: stocks[monthKey],
          stockOut: stockOut[monthKey],
        };
      })
      .sort((a, b) => {
        if (a.yr !== b.yr) {
          return b.yr - a.yr;
        }
        return b.mon - a.mon;
      });

    this.accountsOverallTotals = { sells: 0, income: 0, buy: 0, outgoings: 0 };
    result.forEach((r) => {
      this.accountsOverallTotals.sells += r.sells;
      this.accountsOverallTotals.income += r.income;
      this.accountsOverallTotals.buy += r.stock;
      this.accountsOverallTotals.outgoings += r.outgoings;
    });
    this._dataService.setLS(
      "accountsOverallTotals",
      this.accountsOverallTotals
    );
    return result;
  }

  async setOveralls() {
    // OVERALLS TABLE
    // console.time("getOveralls");
    this.accountsOverall.data = await this.getOveralls();
    // console.timeEnd("getOveralls");

    /* LINE CHART */
    let labels = [],
      chartData = [];

    for (let i = 10; i >= 0; i--) {
      if (
        this.accountsOverall.data[i] &&
        this.accountsOverall.data[i].sells > 0
      ) {
        labels.push(
          this.accountsOverall.data[i].mon +
            "/" +
            this.accountsOverall.data[i].yr
        );
        chartData.push(this.accountsOverall.data[i].sells);
      }
    }

    this.lineChartData.datasets[0] = {
      data: chartData,
    };
    this.lineChartData.labels = labels;
    this.chart.update();
    this._dataService.setLS("sellsChartData", this.lineChartData);

    localStorage.setItem(
      "accountsOverall",
      JSON.stringify(this.accountsOverall.data)
    );
    this.dataLoading = false;
  }

  async getLastTransactions() {
    this.lastTransactions = await db.transactions.reverse().limit(25).toArray();

    this.lastTransactions.map((item) => {
      item.customerName =
        _.find(this._dataService.customers(), {
          id: item.customerId,
        })?.name || "*";
      item.tType = this._dataService.getTransactionType(item.type, item);
    });
    this._dataService.setLS("lastTransactions", this.lastTransactions);
  }

  addToDo(toDoText) {
    if (toDoText) {
      this._dataService.todos.set([
        ...this._dataService.todos(),
        {
          todo: toDoText,
          id: 0,
          userId: this._authService.loggedInUserValue.id,
        },
      ]);
      localStorage.setItem("todos", JSON.stringify(this._dataService.todos()));
      this.http
        .post(this._dataService.dbBase + `api.php/records/todos/`, {
          todo: toDoText,
          userId: this._authService.loggedInUserValue.id,
          id: null,
        })
        .subscribe((r) => {
          this.toastr.success("", "Əlavə edildi!", {
            toastClass: "ngx-toastr customToast",
            progressBar: true,
            timeOut: 2500,
            progressAnimation: "increasing",
          });
          const item = this._dataService.todos().find((item) => item.id === 0);
          item.id = r;
        });
    }
  }

  doneToDo(todoId) {
    let todo = this._dataService.todos().find((todo) => todo.id == todoId);
    todo.status = 1;

    this.http
      .put(this._dataService.dbBase + `api.php/records/todos/${todoId}`, {
        status: 1,
      })
      .subscribe(() =>
        this.toastr.success("", "Uğurlu!", {
          toastClass: "ngx-toastr customToast",
          progressBar: true,
          timeOut: 2500,
          progressAnimation: "increasing",
        })
      );
  }

  removeToDo(todoId) {
    const index = this._dataService
      .todos()
      .findIndex((item) => item.id === todoId);
    if (index > -1) {
      this._dataService.todos().splice(index, 1);
    }
    this.http
      .delete(this._dataService.dbBase + `api.php/records/todos/${todoId}`)
      .subscribe(() =>
        this.toastr.success("", "Silindi!", {
          toastClass: "ngx-toastr customToast",
          progressBar: true,
          timeOut: 2500,
          progressAnimation: "increasing",
        })
      );
  }

  async getTotalProduction() {
    let mTotals = await this._stockService.getMaterialTotals(
      this._dataService.endDate.value.format("YYYY-MM-DD")
    );

    let usedMaterials =
      await this._stockService.getUsedMaterialsForProduction();

    usedMaterials.map((m) => {
      let material = mTotals.find((mm) => mm.materialId == m.materialId);
      m.unitPrice = material.unitPrice;
      m.totalPrice = m.sum * m.unitPrice;
    });

    const totalQuantity = usedMaterials.reduce(
      (acc, curr) => acc + curr.sum,
      0
    );
    const totalPrice = usedMaterials.reduce(
      (acc, curr) => acc + curr.totalPrice,
      0
    );

    let tKG = 0;
    let res = await db.production
      .where("date")
      .between(
        this._dataService.startDate.value.format("YYYY-MM-DD"),
        this._dataService.endDate.value.format("YYYY-MM-DD"),
        true,
        true
      )
      .toArray();
    res.forEach(
      (t) => (tKG += t.quantity * t.length * t.weight * (t.width || 1))
    );
    tKG = parseFloat(tKG?.toFixed(0)) ?? 0;
    return { totalQuantity, totalPrice };
  }

  getNextCashboxId(): number {
    const currentIndex = this._dataService
      .cashBox()
      .findIndex(
        (cashbox) => cashbox.id === this._bankService.selectedCashboxId()
      );
    const nextIndex = (currentIndex + 1) % this._dataService.cashBox().length;
    if (currentIndex == this._dataService.cashBox().length - 1) {
      this._bankService.selectedCashboxId.set(0);
      this._bankService._selectedCashboxId$.next(0);
      this.selectedCashboxName.set("Kassa");
    } else {
      this._bankService.selectedCashboxId.set(
        this._dataService.cashBox()[nextIndex].id
      );
    }

    return this._bankService.selectedCashboxId();
  }

  getNextStockGroupId(): number {
    const currentIndex = this._dataService
      .stockGroups()
      .findIndex(
        (cashbox) => cashbox.id === this._stockService.selectedStockGroupId()
      );
    const nextIndex =
      (currentIndex + 1) % this._dataService.stockGroups().length;
    if (currentIndex == this._dataService.stockGroups().length - 1) {
      this._stockService.selectedStockGroupId.set(0);
      this._stockService._selectedStockGroupId$.next(0);
      this.selectedStockGroupName.set("Anbar");
    } else {
      this._stockService.selectedStockGroupId.set(
        this._dataService.stockGroups()[nextIndex].id
      );
    }

    return this._bankService.selectedCashboxId();
  }

  async changeDB(database?) {
    this._dataService.dbChanged.set(database?.db ? database.db : database);
    this._syncService.showSyncLine = true;
    this.dataLoading = true;
    this._dataService.dbTable = database?.db ? database.db : database;
    // switchDatabase(database?.db ? database.db : database);
    // this._syncService.syncDate = this._dataService.getLS("syncDate");
    // this._syncService.getLSData();
    if (navigator.onLine) {
      this._syncService.syncDB();
      this._syncService.getSettings();
    }
    this.changeDBNavButtons();
    this.getData();
    this.setOveralls();
  }

  changeDBNavButtons() {
    this.navButtons = [];
    const selectedDB = this._dataService.database.find(
      (db) => db.db === this._dataService.dbTable
    );

    if (selectedDB) {
      this.navButtons.push({
        name: selectedDB.name,
        icon: "hard_drive",
        function: () => this.changeDB(selectedDB),
      });
    }

    this._dataService.database.forEach((db) => {
      if (db !== selectedDB) {
        this.navButtons.push({
          name: db.name,
          icon: "switch_left",
          function: () => this.changeDB(db),
        });
      }
    });
  }

  changeSelectedDate(row) {
    let endOfMonth = moment(`${row.mon}-01-${row.yr}`)
      .endOf("month")
      .daysInMonth();
    this._dataService.startDate = new UntypedFormControl(
      moment(`${row.mon}-01-${row.yr}`)
    );
    this._dataService.setLS("startDate", this._dataService.startDate);
    this._dataService.endDate = new UntypedFormControl(
      moment(`${row.mon}-${endOfMonth}-${row.yr}`)
    );
    this._dataService.setLS("endDate", this._dataService.endDate);
    this._dataService.dateChanged.next(moment().startOf("month").toString());
    this._dataService.setActiveClassOnDates();
  }
}
